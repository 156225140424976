import React from 'react';
import { NavLink } from 'react-router-dom';
import { Constants, Context } from '../stores';

const ModalUser = () => {
    const [state, setState] = React.useContext(Context);

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <div className="modal fade" id="modalUser" data-bs-dismiss="modal">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div className="modal-content border-0 rounded-3">
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-4 p-lg-5 p-4 order-lg-0 order-1">
                            <ul className="list-unstyled mb-0 d-grid gap-3">
                                <li>
                                    <NavLink to="/hesap/profilim" className="text-decoration-none text-gray-darker">Hesap Bilgilerim</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/hesap/adreslerim" className="text-decoration-none text-gray-darker">Adres Bilgilerim</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/hesap/mesajlarim" className="text-decoration-none text-gray-darker">
                                        <div className="d-flex justify-content-between align-items-center">
                                            Mesajlarım
                                            {state.responseMessageCount ? (
                                            <div className="bg-orange text-white px-3 py-1 rounded-pill small">
                                                {state.responseMessageCount}
                                            </div>
                                        ) : null}
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/hesap/fotograflarim" className="text-decoration-none text-gray-darker">Fotoğraflarım</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/hesap/puanlarim" className="text-decoration-none text-gray-darker">Puan Geçmişim</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/hesap/siparislerim" className="text-decoration-none text-gray-darker">Sipariş Bilgilerim</NavLink>
                                </li>
                                <li>
                                    <a href="" className="text-decoration-none text-gray-darker" onClick={() => handleLogout()}>Çıkış Yap</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-8 p-lg-5 p-4 order-lg-1 order-0">
                            <h1 className="display-2 fw-bolder d-flex align-items-center justify-content-center text-green mb-4">{state.responseLogin.employeeFirm.score.score} <span className="h6 mb-0 ms-3">Puan</span></h1>
                            <div className="d-grid gap-3">
                                {state.responseScoreTransactions ? (
                                    state.responseScoreTransactions.slice(0, 3).map((item, i) => (
                                        <div className="d-flex align-items-center" key={i}>
                                            <span className="text-blue-light small">{item.dateCreated.replaceAll('-', '.')}</span>
                                            <span className="mx-3 small">{item.scoreTypeName}</span>
                                            {item.scoreType == 'SIPARIS' ? (
                                                <span className="badge rounded-pill bg-red px-3 py-2 ms-auto">-{item.changedScore} Puan</span>
                                            ) : (
                                                <span className="badge rounded-pill bg-green px-3 py-2 ms-auto">+{item.changedScore} Puan</span>
                                            )}
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalUser;