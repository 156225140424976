import React from 'react';
import { ModalInfo, ModalKVKK } from '../components';

const LoginFooter = () => {
    return (
        <React.Fragment>
            <div className="text-center mt-md-8 mt-5">
                <p><a href="tel:08505325787" className="text-gray-darkest text-decoration-none"><i className="fas fa-phone-alt me-3"></i>0850 532 5787</a></p>
                <p className="fw-lighter opacity-50">Copyright © 2023 Dizayn Grup. Tüm Hakları Saklıdır.</p>
                <p className="small mb-0 opacity-50">
                    <a href="#" className="text-decoration-none text-reset me-3" data-bs-toggle="modal" data-bs-target="#modalInfo">Aydınlatma Metni</a>
                    <a href="#" className="text-decoration-none text-reset" data-bs-toggle="modal" data-bs-target="#modalKVKK">Kişisel Veriler Hakkında Bildirim</a>
                </p>
            </div>

            <ModalInfo />
            <ModalKVKK />
        </React.Fragment>
    );
};

export default LoginFooter;