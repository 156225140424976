import React from 'react';
import {
    NavLink,
    Navigate,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import { Footer, Header } from '../../components';

const Posts = () => {
    const navigate = useNavigate();
    const { postCategory } = useParams();
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {
        if (state.isAuthorized) {
            
            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }
        }
    }, []);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    if (!postCategory) {
        return <Navigate to={`${state.responsePostCategories[0].id}`} />;
    }

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="bg-blue py-5 mb-sm-5 mb-3 d-lg-block d-none">
                    <div className="container">
                        <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <span className="me-3">Bizim Dünya</span>
                            <span className="me-3">»</span>
                            <span>Bizim Dergi</span>
                        </div>
                    </div>
                </section>

                <section className="mb-sm-10 mb-5 mt-lg-0 mt-sm-5 mt-3">
                    <div className="container">
                        <div className="row gy-0">
                            <div className="col-xl-3 col-lg-4 order-1">
                                <div className="ps-lg-5 ps-0">
                                    <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                                        <div className="offcanvas-header">
                                            <h5 className="offcanvas-title">Kategoriler</h5>
                                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div className="offcanvas-body">
                                            <h4 className="mb-4 d-lg-block d-none">Kategoriler</h4>
                                            <ul className="list-unstyled d-grid gap-3 mb-lg-8 mb-5">
                                                {state.responsePostCategories.map((item) => (
                                                    <li key={item.id}>
                                                        <NavLink to={`/bizim-dergi/${item.id}`} className="text-decoration-none">{item.name}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>

                                            <h4 className="mb-4">Bizim TV</h4>
                                            {state.responseVideoCategories[0].items.slice(0, 5).map((item) => (
                                                <a href={Constants.VIDEOS_BASE_URL + '/' + item.videoUrl} className="d-flex align-items-center text-reset text-decoration-none border-bottom pb-3 mb-3" data-fancybox key={item.id}>
                                                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" className="img-contain rounded-3" style={{width: '60px', height: '60px'}} />
                                                    <h6 className="mb-0 small fw-normal ms-3">{item.name}</h6>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                    <a href=".offcanvas" className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3" data-bs-toggle="offcanvas">
                                        <i className="fas fa-list-alt"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8 order-0">
                                {state.responsePostCategories.filter((item) => {
                                    return item.id == postCategory;
                                })[0].items.slice(0, 1).map((item) => (
                                    <NavLink to={`/makale/${item.id}`} className="d-block overflow-hidden position-relative rounded-3 mb-sm-5 mb-3 mt-lg-n8 mt-0" key={item.id}>
                                        <img src={Constants.IMAGES_BASE_URL + '/' + item.topIcon} alt="" className="w-100 img-cover" style={{width: '960px', height: '480px'}} />
                                        <div className="position-absolute bottom-0 w-100 p-sm-5 p-4 overlay">
                                            <span className="d-block small text-white mb-3">{item.dateCreated} <span className="ms-3">#{state.responsePostCategories.filter((item) => { return item.id == postCategory; })[0].name}</span></span>
                                            <h2 className="mb-0 lh-base text-white">{item.name}</h2>
                                        </div>
                                    </NavLink>
                                ))}

                                <div className="mb-sm-n4 mb-n2">
                                    {state.responsePostCategories.filter((item) => {
                                        return item.id == postCategory;
                                    })[0].items.slice(1, 9999).map((item) => (
                                        <NavLink to={`/makale/${item.id}`} className="d-block overflow-hidden position-relative rounded-3 shadow text-decoration-none text-reset mb-sm-4 mb-2" key={item.id}>
                                            <div className="row g-0 align-items-stretch">
                                                <div className="col-md-5">
                                                    <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-cover w-100 h-100" style={{width: '300px', height: '200px'}} />
                                                </div>
                                                <div className="col-md-7 px-sm-5 px-4 py-4 align-self-center">
                                                    <span className="d-block text-blue small mb-1">{item.dateCreated}</span>
                                                    <h5 className="mb-3 lh-base">{item.name}</h5>
                                                    <p className="fw-light small mb-0 d-xl-block d-none">{item.description.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 100)}...</p>
                                                    <span className="d-block text-blue small mt-4">#{state.responsePostCategories.filter((item) => { return item.id == postCategory; })[0].name}</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Posts;