import React from 'react';
import $ from 'jquery';

const TitleBackground = ({className}) => {

    const setTitleBackground = () => {
        setTimeout(function() {
            let height = 0;
            $('[data-title-background]:visible').each(function() {
                var data = $(this).data('title-background');
                if (data == 'pt') {
                    height = parseInt(height + $(this).css('padding-top'));
                } else if (data == '' || data == true) {
                    height = parseInt(height + $(this).outerHeight(true));
                }
            });
            $('.titleBackground').height(height);
        }, 10);
    }

    React.useEffect(() => {
        $(window).resize(function() { setTitleBackground(); });
        setTitleBackground();
    });

    return (
        <div className={`titleBackground ${className ? className : ''}`}></div>
    );
};

export default TitleBackground;