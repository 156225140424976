import React from 'react';
import {
    NavLink,
    Navigate,
    useLocation,
    useNavigate
} from 'react-router-dom';
import $ from 'jquery';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    TitleBackground
} from '../../components';

const GameMatchIt = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = React.useContext(Context);
    const [pageState, setPageState] = React.useState({
        gameMatchItStarted: false,
        gameMatchItDone: false,
        gameMatchItBrandClicked: null,
        gameMatchItPropertyClicked: null,
        isConfirmDisabled: true,
    });

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }

            //responseGameMatchIt
            fetch(Constants.API_BASE_URL + '/pickFind/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                if (response?.data?.[0]) {
                    setState({
                        ...state,
                        responseGameMatchIt: response['data'][0]
                    });
                }
            });

        }
    }, []);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const updateUser = async (newState) => {
        let tempState = {};
    
        //responseLogin.employeeFirm
        await fetch(Constants.API_BASE_URL + '/newScore/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            tempState = {
                ...tempState,
                responseLogin: {
                    ...state.responseLogin,
                    employeeFirm: response['data']
                }
            }
        });
    
        //responseScoreTransactions
        await fetch(Constants.API_BASE_URL + '/scoreTransactions/' + tempState.responseLogin.userId + '?page=0&size=9999', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tempState.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            if (response?.data?.scoreTransactionDTOS) {
                tempState = {
                    ...tempState,
                    responseScoreTransactions: response['data']['scoreTransactionDTOS']
                }
            }
        });

        setState({
            ...newState ? newState : state,
            ...tempState
        });
    };

    const handleStartClick = (event) => {
        event.preventDefault();
        if (!pageState?.gameMatchItStarted) {

            //responseGameMatchItQuestion
            fetch(Constants.API_BASE_URL + '/pickFindQuestion/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                if (response?.data?.[0]) {
                    setState({
                        ...state,
                        responseGameMatchItQuestion: response['data'][0]
                    });
                    setPageState({
                        ...pageState,
                        gameMatchItStarted: Date.now()
                    });
                }
            });
        }
    }

    const handleBrandClick = (event, id) => {
        event.preventDefault();

        if ($(event.currentTarget).hasClass('match-item-wrong') || $(event.currentTarget).hasClass('match-item-confirmed')) {
            return false;
        }

        $(event.currentTarget)
            .parent()
            .siblings()
            .find('a')
            .removeClass('match-item-active');
        $(event.currentTarget).toggleClass('match-item-active');

        setPageState({
            ...pageState,
            gameMatchItBrandClicked: pageState.gameMatchItBrandClicked == id ? null : id,
            isConfirmDisabled: (pageState.gameMatchItBrandClicked == id ? null : id) && pageState.gameMatchItPropertyClicked ? false : true
        });
    };

    const handlePropertyClick = (event, id) => {
        event.preventDefault();

        if ($(event.currentTarget).hasClass('match-item-wrong') || $(event.currentTarget).hasClass('match-item-confirmed')) {
            return false;
        }

        $(event.currentTarget)
            .parent()
            .siblings()
            .find('a')
            .removeClass('match-item-active');
        $(event.currentTarget).toggleClass('match-item-active');

        setPageState({
            ...pageState,
            gameMatchItPropertyClicked: pageState.gameMatchItPropertyClicked == id ? null : id,
            isConfirmDisabled: (pageState.gameMatchItPropertyClicked == id ? null : id) && pageState.gameMatchItBrandClicked ? false : true
        });
    };

    const handleConfirmClick = (event) => {
        event.preventDefault();

        if (pageState.gameMatchItBrandClicked == pageState.gameMatchItPropertyClicked) {
            $('.match-item-active')
                .removeClass('match-item-active')
                .addClass('match-item-confirmed');

            setPageState({
                ...pageState,
                gameMatchItBrandClicked: null,
                gameMatchItPropertyClicked: null,
                isConfirmDisabled: true
            });

            if ($('.match-item-confirmed').length == 6) {
                //done
                fetch(Constants.API_BASE_URL + '/pickFind/save', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + state.responseLogin.token
                    },
                    body: JSON.stringify({
                        employeeFirmId: state.responseLogin.employeeFirm.id,
                        firmId: state.responseLogin.employeeFirm.firm.id,
                        pickFindId: state.responseGameMatchIt.pickFindId
                    })
                })
                .then((response) => {
                    if (response.status == 200) {
                        updateUser();
                        setPageState({
                            ...pageState,
                            gameMatchItStarted: false,
                            gameMatchItDone: true,
                            gameMatchItBrandClicked: null,
                            gameMatchItPropertyClicked: null,
                            isConfirmDisabled: true,
                        });
                    }
                });
            }
        } else {
            $('.match-item-active')
                .removeClass('match-item-active')
                .addClass('match-item-wrong');

            setTimeout(() => {
                setPageState({
                    ...pageState,
                    gameMatchItBrandClicked: null,
                    gameMatchItPropertyClicked: null,
                    isConfirmDisabled: true
                });
                $('.match-item-wrong').removeClass('match-item-wrong');
            }, 500);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5" data-title-background="pt">
                    <TitleBackground className="d-lg-block d-none" />
                    <div className="container">
                        <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <span className="text-white text-decoration-none me-3">Yarışmalar</span>
                        </div>
                        <div className="row gy-5">
                            <div className="col-xl-3 col-lg-4">
                                <div className="gameItem mb-5">
                                    <div className="bg-white shadow-lg rounded-3 px-4 pb-sm-5 pb-4 text-center">
                                        <div className="image shadow-lg bg-white rounded-circle">
                                            <img src="../assets/img/game03.png" alt="" />
                                        </div>
                                        <h2 className="mb-0 fw-bold text-blue lh-sm">Eşini Bul</h2>

                                        {pageState.gameMatchItDone ? null : (
                                            state.responseGameMatchIt?.availableTodayPickFind ? (
                                                //available
                                                state.responseGameMatchIt?.answeredTodayPickFind ? (
                                                    //daha once cevaplamis
                                                    <p className="small text-orange mt-4"><i className="fas fa-info-circle me-2"></i>{state.responseGameMatchIt?.answeredNextDayMessage}</p>
                                                ) : (
                                                    pageState?.gameMatchItStarted ? (
                                                        //cevaplamamis, baslamis
                                                        <p className="text-start small text-blue-light mt-4"><i className="fas fa-info-circle me-2"></i>Üst taraftaki ürünler alt taraftaki ürün özellikleri ile tek tek eşleştirip her biri için Doğrula butonuna basınız. Kutular kırmızı ise yanlış eşleştirme yapmışsınız demektir, farklı kutuları seçip tekrar deneyebilirsiniz.</p>
                                                    ) : (
                                                        //cevaplamamis, baslamamis
                                                        <button type="button" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3 mt-4" onClick={(event) => handleStartClick(event)}>Başla</button>
                                                    )
                                                )
                                            ) : (
                                                //availabble degil, kalan sure
                                                state.responseRemaining.remainingToPickFind ? (
                                                    <React.Fragment>
                                                        <div className="d-flex text-green align-items-center justify-content-between small mb-2">
                                                            <span>Kalan Süre</span>
                                                            <span>{state.responseRemaining.remainingToPickFind}</span>
                                                        </div>
                                                        <div className="bg-green-light rounded-pill overflow-hidden" style={{height: '8px'}}>
                                                            <div className="bg-green rounded-pill h-100" style={{width: state.responseRemaining.progressPickFind + '%'}}></div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 offset-lg-1 offset-0">
                                <h1 className="mb-5 text-white text-white-lg" data-title-background>Eşini Bul’a hoşgeldiniz!</h1>

                                {pageState.gameMatchItDone ? (
                                    <div className="pt-lg-5 pt-0 fw-light">
                                        <h2 className="mb-4">🎉 Tebrikler</h2>
                                        <p className="mb-0 lh-lg">Tüm eşleştirmeleri doğru yaptınız ve <strong>{state.responseGameMatchItQuestion.score} puan</strong> kazandınız. {state.responseGameMatchItQuestion.answeredNextDayMessage}</p>
                                    </div>
                                ) : (
                                    !pageState?.gameMatchItStarted ||
                                    !state.responseGameMatchIt?.availableTodayPickFind ||
                                    state.responseGameMatchIt?.answeredTodayPickFind ? (
                                        <div className="pt-lg-5 pt-0 fw-light">
                                            <p>Her hafta Pazartesi, Salı, Perşembe ve Cuma günleri karşınıza çıkar. <strong>Çeşitli ürünlere ait özellikleri doğru olarak eşleştirin ve 10 puan kazanın</strong>.</p>
                                            <p>Bugünün Eşini Bul sorusu için <strong>Başla</strong> botununa tıklayınız. Başarılar!</p>
                                        </div>
                                    ) : (
                                        <div className="pt-lg-5 pt-0">
                                            <h4 className="mb-5">Ürünler</h4>
                                            <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 g-3 mb-6">
                                                {state.responseGameMatchItQuestion?.brandStyleNames.map((item, i) => (
                                                    <div className="col" key={i}>
                                                        <a href="#" className="shadow-lg rounded-3 overflow-hidden d-block h-100 text-decoration-none match-item" onClick={(event) => handleBrandClick(event, item.id)}>
                                                            <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 p-3 bg-white" alt="" style={{maxHeight: '180px'}} />
                                                            <div className="text-center p-4">
                                                                <h4 className="text-blue-dark mb-0">{item.name}</h4>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>

                                            <h4 className="mb-5">Özellikler</h4>
                                            <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 g-3 mb-sm-5 mb-4">
                                                {state.responseGameMatchItQuestion?.brandStyleProperties.map((item, i) => (
                                                    <div className="col" key={i}>
                                                        <a href="#" className="shadow-lg rounded-3 overflow-hidden d-block h-100 text-decoration-none text-reset p-3 match-item" onClick={(event) => handlePropertyClick(event, item.brandStyleId)}>
                                                            {item.brandStylePropertyList.map((property, i) => (
                                                                <div className={`small${i + 1 == item.brandStylePropertyList.length ? '' : ' border-bottom pb-2 mb-2'}`} key={i}>
                                                                    <h6 className="mb-1">{property.propertyName}</h6>
                                                                    <span>{property.propertyValue}</span>
                                                                </div>
                                                            ))}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="text-lg-start text-center">
                                                <button type="button" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3" onClick={(event) => handleConfirmClick(event)} disabled={pageState.isConfirmDisabled}>Doğrula</button>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </React.Fragment>
    );
};

export default GameMatchIt;