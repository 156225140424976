import React from 'react';
import { Navigate } from 'react-router-dom';
import {
    NavLink,
    useLocation,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    TitleBackground
} from '../../components';

const GameBestStores = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { gamePage } = useParams();
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }

            getContestants();
        }
    }, []);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const getContestants = async () => {
        let tempState = {};

        await fetch(Constants.API_BASE_URL + '/weekStoreToBeVotedList/' +  state.responseLogin.employeeFirm.firm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            if (response?.['data']) {
                tempState = {
                    ...tempState,
                    responseContestants: response['data']
                }
            }
        });

        if (tempState?.responseContestants?.[0].hdCompetitionToBeVoted?.hdCompetition?.id) {
            await fetch(Constants.API_BASE_URL + '/myVotes/' +  state.responseLogin.employeeFirm.id + '/' + tempState.responseContestants[0].hdCompetitionToBeVoted.hdCompetition.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                if (response?.['data']) {
                    tempState = {
                        ...tempState,
                        responseMyVotes: response['data']
                    }
                }
            });
        }

        await fetch(Constants.API_BASE_URL + '/weekWinnerList/' +  state.responseLogin.employeeFirm.firm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            if (response['data']) {
                tempState = {
                    ...tempState,
                    responseContestantWinners: response['data']
                }
            }
        });

        setState({
            ...state,
            ...tempState
        });
    };

    const handleVoteContestant = (event, competitionId, competitionToBeVotedId) => {
        event.preventDefault();

        fetch(Constants.API_BASE_URL + '/doVote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify({
                competitionId: competitionId,
                competitionToBeVotedId: competitionToBeVotedId,
                employeeFirmId: state.responseLogin.employeeFirm.id
            })
        })
        .then((response) => {
            if (response.status == 200) {
                getContestants();
                window.showToast('Oyunuz için teşekkürler.');
            }
        });
    };

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5" data-title-background="pt">
                    <TitleBackground className="d-lg-block d-none" />
                    <div className="container">
                        <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <span className="text-white text-decoration-none me-3">Yarışmalar</span>
                        </div>
                        <div className="row gy-5">
                            <div className="col-xl-3 col-lg-4">
                                <div className="gameItem mb-5">
                                    <div className="bg-white shadow-lg rounded-3 px-4 pb-sm-5 pb-4 text-center">
                                        <div className="image shadow-lg bg-white rounded-circle">
                                            <img src="../assets/img/game04.png" alt="" />
                                        </div>
                                        <h2 className="mb-0 fw-bold text-blue lh-sm">Haftanın En’leri</h2>
                                    </div>
                                </div>

                                <ul className="list-unstyled d-grid gap-3 mb-lg-8 mb-0">
                                    <li><NavLink to={`/yarisma/haftanin-enleri`} className={`text-decoration-none${gamePage ? ' text-blue' : ''}`}>Bu Haftanın Yarışmacıları</NavLink></li>
                                    <li><NavLink to={`/yarisma/haftanin-enleri/kazananlar`} className="text-decoration-none">Haftanın Kazananları</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-xl-8 col-lg-7 offset-lg-1 offset-0">
                                <h1 className="mb-5 text-white text-white-lg" data-title-background>Haftanın En’lerine hoşgeldiniz!</h1>
                                <div className="pt-lg-5 pt-0 fw-light">
                                    <p>Bu yarışmamızda her hafta 8 farklı bayimizin dükkan fotoğrafı oylarınızı bekliyor. En çok beğendiğiniz fotoğrafa oy vererek <strong>her hafta 20 puan kazanabilirsiniz</strong>. Sadece ilk oylamanızdan puan kazanacaksınız ama dilerseniz birden fazla oy kullanabilir ve diğer bayilerimizin dükkanlarını da destekleyebilirsiniz. Her hafta en çok oy alan fotoğraf, Haftanın Dükkanı seçilecek ve Haftanın Kazananları bölümünde ilan edilecektir. Haftanın Dükkanı seçilen bayi, seçilmiş olduğu hafta boyunca dükkan fotoğrafını güncelleyemez.</p>
                                    <p>Her haftanın en çok oy alan dükkan fotoğraflarını görmek için <strong>Haftanın Kazananları</strong> bölümünü inceleyebilirsiniz. Haftanın Dükkanı’nı belirlemek için aşağıdaki en beğendiğiniz fotoğrafı tıklayınız.</p>

                                    {gamePage == 'kazananlar' ? (
                                        state.responseContestantWinners ? (
                                            <React.Fragment>
                                                <h4 className="my-5">Haftanın Kazananları</h4>

                                                <div className="mb-n4">
                                                    {state.responseContestantWinners.map((item, i) => (
                                                        <div className="row rounded-3 overflow-hidden g-0 shadow-sm mb-4 align-items-stretch" key={i}>
                                                            <div className="col-4">
                                                                <img src={Constants.IMAGES_BASE_URL + '/' + item.employeeImage.imageUrl} alt="" className="w-100 img-cover" style={{height: '180px'}} />
                                                            </div>
                                                            <div className="col-8 p-4 d-flex align-self-center">
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-xl-6">
                                                                        <div className="d-flex align-items-center">
                                                                            <h1 className="mb-0 text-orange d-xxl-block d-none"><i className="fas fa-trophy me-3"></i></h1>
                                                                            <h4 className="mb-0 text-orange">{i + 1}. Hafta Kazananı</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-6">
                                                                        <h5 className="mb-2 overflow-hidden text-truncate">{item.hdCompetitionWinner.hdCompetitionToBeVoted.employeeFirm.employee.fullName}</h5>
                                                                        <p className="mb-0 overflow-hidden text-truncate">{item.hdCompetitionWinner.hdCompetitionToBeVoted.employeeFirm.vendorName}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ) : null
                                    ) : (
                                        state.responseContestants ? (
                                            <React.Fragment>
                                                <h4 className="my-5">Bu Haftanın Yarışmacıları</h4>

                                                <div className="row row-cols-xl-4 row-cols-md-3 row-cols-2 g-3">
                                                    {state.responseContestants.map((item, i) => (
                                                        <div className="col" key={i}>
                                                            <div className="bg-white shadow-sm rounded-3 overflow-hidden position-relative has-hover">
                                                                <img src={Constants.IMAGES_BASE_URL + '/' + item.employeeImage.imageUrl} alt="" className="w-100 img-cover" style={{height: '180px'}} />
                                                                <span className="rounded-pill bg-orange text-white small position-absolute start-0 top-0 px-3 py-1 m-sm-3 m-2">{item.hdCompetitionToBeVoted.votesReceived} Oy</span>
                                                                <div className={`px-3 py-4 overflow-hidden text-truncate${state?.responseMyVotes?.includes(item.hdCompetitionToBeVoted.id) ? ' bg-green text-white' : ''}`}>
                                                                    <h6 className={`mb-1 text-truncate${state?.responseMyVotes?.includes(item.hdCompetitionToBeVoted.id) ? ' text-white' : ''}`}>
                                                                        {state?.responseMyVotes?.includes(item.hdCompetitionToBeVoted.id) ? (<i className="fas fa-check-circle me-2"></i>) : null}
                                                                        {item.hdCompetitionToBeVoted.employeeFirm.employee.fullName}
                                                                    </h6>
                                                                    <span className={`small${state?.responseMyVotes?.includes(item.hdCompetitionToBeVoted.id) ? ' text-white' : 'text-blue-dark'}`}>{item.hdCompetitionToBeVoted.employeeFirm.vendorName}</span>
                                                                </div>
                                                                {!state?.responseMyVotes?.includes(item.hdCompetitionToBeVoted.id) ? (
                                                                    <div className="hover position-absolute w-100 h-100 top-0 start-0 d-flex justify-content-center align-items-center" style={{backgroundColor: 'rgba(255, 255, 255, 0.75)'}}>
                                                                        <button type="button" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3" onClick={(event) => handleVoteContestant(event, item.hdCompetitionToBeVoted.hdCompetition.id, item.hdCompetitionToBeVoted.id)}>Oy Ver</button>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </React.Fragment>
    );
};

export default GameBestStores;