import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Constants, Context } from '../../stores';
import {
    Loading,
    LoginFooter,
    Toast
} from '../../components';

const Login = () => {
    const navigate = useNavigate();
    const [state, setState] = React.useContext(Context);

    const handleSubmit = (event) => {
        event.preventDefault();

        setState({
            ...state,
            isLoading: true
        });

        fetch(Constants.API_BASE_URL + '/public/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Object.fromEntries(new FormData(event.target)))
        })
        .then(response => response.json())
        .then(response => {
            if (response['errors']) {
                window.showToast(response['errors'][0]['message'], true);
                setState({
                    ...state,
                    isLoading: false
                });
            } else {
                const tempState = {
                    ...state,
                    isLoading: false,
                    isAuthorized: true,
                    responseLogin: response['data'],
                    loginTime: Date.now()
                }

                Promise.all([

                    //responseAnnouncements
                    fetch(Constants.API_BASE_URL + '/announcementList/' + tempState.responseLogin.employeeFirm.firm.id + '/' + tempState.responseLogin.employeeFirm.id + '/TOP', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response['data']; }),

                    //responseAnnouncementListSlider
                    fetch(Constants.API_BASE_URL + '/announcementList/' + tempState.responseLogin.employeeFirm.firm.id + '/' + tempState.responseLogin.employeeFirm.id + '/SLIDER', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response['data']; }),
    
                    //responseBrandList
                    fetch(Constants.API_BASE_URL + '/brandList/' +  tempState.responseLogin.employeeFirm.firm.id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => {
                        return Promise.all(
                            response['data'].map((item) => {

                                //responseBrandStyleList
                                return fetch(Constants.API_BASE_URL + '/brandStyleList/' +  item.id, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + tempState.responseLogin.token
                                    }
                                })
                                .then((response) => { return response.json(); })
                                .then((response) => { return response['data']; })
                            })
                        ).then((values) => {
                            return response['data'].map((item, i) => {
                                item['items'] = values[i];
                                return item;
                            });
                        });
                    }),

                    //responseProductCategories
                    fetch(Constants.API_BASE_URL + '/category/' +  tempState.responseLogin.employeeFirm.firm.id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response['data']}),

                    //responseProducts
                    fetch(Constants.API_BASE_URL + '/product/0/true/0?page=0&size=9999', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response['data']['productDTOS']; }),

                    //responseVideoCategories
                    fetch(Constants.API_BASE_URL + '/topicSubCategory/' +  tempState.responseLogin.employeeFirm.firm.id + '/Benim%20Bayim%20TV', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => {
                        return Promise.all(
                            response['data'].map((item) => {

                                //responseVideos
                                return fetch(Constants.API_BASE_URL + '/article/' + tempState.responseLogin.employeeFirm.firm.id + '/' +  item.id, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + tempState.responseLogin.token
                                    }
                                })
                                .then((response) => { return response.json(); })
                                .then((response) => { return response['data']; })
                            })
                        ).then((values) => {
                            return response['data'].map((item, i) => {
                                item['items'] = values[i];
                                return item;
                            });
                        });
                    }),

                    //responsePostCategories
                    fetch(Constants.API_BASE_URL + '/topicSubCategory/' +  tempState.responseLogin.employeeFirm.firm.id + '/Bizim%20Dergi', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => {
                        return Promise.all(
                            response['data'].map((item) => {

                                //responseVideos
                                return fetch(Constants.API_BASE_URL + '/article/' + tempState.responseLogin.employeeFirm.firm.id + '/' +  item.id, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + tempState.responseLogin.token
                                    }
                                })
                                .then((response) => { return response.json(); })
                                .then((response) => { return response['data']; })
                            })
                        ).then((values) => {
                            return response['data'].map((item, i) => {
                                item['items'] = values[i];
                                return item;
                            });
                        });
                    }),

                    //responseSurvey
                    fetch(Constants.API_BASE_URL + '/survey/' + tempState.responseLogin.employeeFirm.firm.id + '/' + tempState.responseLogin.userId, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response['data']; }),

                    //responseScoreTransactions
                    fetch(Constants.API_BASE_URL + '/scoreTransactions/' + tempState.responseLogin.userId + '?page=0&size=9999', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response['data']['scoreTransactionDTOS']; }),

                    //responseRemaining
                    fetch(Constants.API_BASE_URL + '/remaining/' + tempState.responseLogin.employeeFirm.firm.id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response; }),

                    //responseComments
                    fetch(Constants.API_BASE_URL + '/forum/findAll/' + tempState.responseLogin.employeeFirm.firm.id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => {
                        if (response['data'] && response['data']['forumSubjectDTOS'] && response['data']['forumSubjectDTOS'][0]) {
                            return response['data']['forumSubjectDTOS'][0];
                        }
                    }),

                    //responseCommentSubjects
                    fetch(Constants.API_BASE_URL + '/forum/past/findAll/' + tempState.responseLogin.employeeFirm.firm.id, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tempState.responseLogin.token
                        }
                    })
                    .then((response) => { return response.json(); })
                    .then((response) => { return response['data']; }),

                ]).then((values) => {

                    localStorage.setItem('state', JSON.stringify({
                        ...tempState,
                        responseAnnouncements: values[0],
                        responseAnnouncementListSlider: values[1],
                        responseBrandList: values[2],
                        responseProductCategories: values[3],
                        responseProducts: values[4],
                        responseVideoCategories: values[5],
                        responsePostCategories: values[6],
                        responseSurvey: values[7],
                        responseScoreTransactions: values[8],
                        responseRemaining: values[9],
                        responseComments: values[10],
                        responseCommentSubjects: values[11]
                    }));

                    setState({
                        ...tempState,
                        responseAnnouncements: values[0],
                        responseAnnouncementListSlider: values[1],
                        responseBrandList: values[2],
                        responseProductCategories: values[3],
                        responseProducts: values[4],
                        responseVideoCategories: values[5],
                        responsePostCategories: values[6],
                        responseSurvey: values[7],
                        responseScoreTransactions: values[8],
                        responseRemaining: values[9],
                        responseComments: values[10],
                        responseCommentSubjects: values[11]
                    });
                    navigate('/');
                });
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    React.useEffect(() => {

        localStorage.clear();

        fetch(Constants.API_BASE_URL + '/public/announcementList', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => response.json())
        .then(response => {
            setState({
                ...state,
                responseAnnouncementLogin: response['data']
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });

        const sliderLogin = new window.Swiper('.sliderLogin', {
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.sliderLogin .swiper-pagination',
                clickable: true
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            }
        });
    }, []);

    return (
        <React.Fragment>
            <section>
                <div className="container-fluid">
                    <div className="row align-items-stretch">
                        <div className="col-lg-6 bg-gray-lightest">
                            <div className="row min-vh-100 align-items-center justify-content-center">
                                <div className="col-xxl-7 col-lg-10 col-md-8 col-10 py-md-8 py-5">
                                    <div className="text-center mb-6">
                                        <img src="../assets/img/logo-transparent.png" alt="" style={{ maxWidth: '200px' }} />
                                    </div>
                                    <h5 className="lh-base text-center fw-normal mb-5">Hoşgeldiniz. Lütfen Kullanıcı Adı ve Şifrenizi kullanarak giriş yapınız.</h5>
                                    <form onSubmit={(event) => handleSubmit(event)}>
                                        <div className="form-floating shadow-lg mb-4 rounded-3">
                                            <input type="text" name="username" className="form-control border-0 rounded-3 px-4" id="inputUsername" placeholder="Kullanıcı Adı" defaultValue="" required />
                                            <label htmlFor="inputUsername" className="px-4"><i className="fas fa-user me-3"></i>Kullanıcı Adı</label>
                                        </div>
                                        <div className="form-floating shadow-lg mb-4 rounded-3">
                                            <input type="password" name="password" className="form-control border-0 rounded-3 px-4" id="inputPassword" placeholder="Şifre" defaultValue="" required />
                                            <label htmlFor="inputPassword" className="px-4"><i className="fas fa-lock me-3"></i>Şifre</label>
                                        </div>
                                        <div className="text-end mb-5">
                                            <NavLink to="/reset" className="small">Şifremi Unuttum</NavLink>
                                        </div>
                                        <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4" disabled={state.isLoading}>
                                            {state.isLoading ? (<span className="spinner-border spinner-border-sm me-3"></span>) : null}
                                            Giriş Yap<i className="fas fa-arrow-right ms-3"></i>
                                        </button>
                                        <div className="text-center text-gray small">
                                            Hesabınız yok mu? <NavLink to="/register">Üye Ol</NavLink>
                                        </div>
                                    </form>

                                    <LoginFooter />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-lg-block d-none bg-blue">
                            <div className="row min-vh-100 align-items-center justify-content-center">
                                <div className="col-xxl-8 col-lg-10 py-md-8 py-5">
                                    <div className="swiper sliderLogin">
                                        <div className="swiper-wrapper mb-md-8 mb-5">
                                            {state.responseAnnouncementLogin ? (
                                                state.responseAnnouncementLogin.map((item) => (
                                                    <div className="swiper-slide" key={item.id}>
                                                        <div className="text-white">
                                                            <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 mb-5" style={{width: '600px', height: '400px'}} />
                                                            <h2 className="text-white mb-3">{item.title}</h2>
                                                            <p className="fw-light mb-5">{item.description}</p>
                                                            {item.url ? (
                                                                <a href={item.url} target={item.newTab ? '_blank' : '_self'} className="btn btn-white text-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">{item.buttonName}<i className="fas fa-arrow-right ms-3"></i></a>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : null}
                                        </div>
                                        <div className="swiper-pagination swiper-pagination-white"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Loading />
            <Toast />
        </React.Fragment>
    );
};

export default Login;