import React from 'react';

const ModalInfo = () => {
    return (
        <div className="modal fade" id="modalInfo">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0 rounded-3 p-4">
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    <h3 className="mb-4">KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ</h3>
                    <p>Şirketimiz bizimkahramanlar.com sitesinde (“Site”) siz bayilerimize ilişkin aldığı kişisel veriler bakımından 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) kapsamında “veri sorumlusu” sıfatına sahip olup işbu Aydınlatma Metni ile söz konusu Kanun uyarınca tarafımızca gerçekleştirilen kişisel veri işleme faaliyetleri hakkında siz bayilerimizi aydınlatılmak isteriz.</p>
                    <h6>Kişisel Verilerin İşlenme Amacı:</h6>
                    <p>Bayilerimize ait kişisel veriler, bayilerimizin bizimkahramanlar.com sitesinde sunulan faaliyetlerden yararlanabilmeleri için gerekli çalışmaların iş birimleri tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi amacıyla ve Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde işlenmektedir.</p>
                    <h6>Bayilere Ait Kişisel Verilerin Aktarımı:</h6>
                    <p>Siz bayilerimize ait kişisel veriler, bizimkahramanlar.com tarafından sunulan faaliyetlerden yararlanabilmeniz için Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde iş ortaklarımız, tedarikçilerimiz ana şirketimiz, kardeş şirketlerimiz , kanunen yetkili kamu kurum ve kuruluşları ile özel kurumlar ile paylaşılabilecektir.</p>
                    <h6>Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi:</h6>
                    <p>Kişisel veriler, siz bayilerimizden elektronik ortamda ve/veya iş ortaklarımız aracılığıyla ve Site’de yer alan faaliyetlere katılabilmeniz amacıyla toplanmaktadır.</p>
                    <h6>Kişisel Veri Sahibi Olarak Müşterilerin Hakları:</h6>
                    <p>Kanun’un 11. maddesi uyarınca veri sahipleri;</p>
                    <ul>
                        <li>a. kendileri ile ilgili kişisel veri işlenip işlenmediğini öğrenme,</li>
                        <li>b. kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                        <li>c. kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                        <li>d. yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                        <li>e. kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                        <li>f. Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                        <li>g. işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve</li>
                        <li>h. kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.</li>
                    </ul>
                    <p>Söz konusu hakların kullanımına ilişkin talepler,BİZİM KAHRAMANLAR GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI İLE İLGİLİ BİLDİRİM’debelirtilen yöntemlerle iletilebilecektir.</p>
                </div>
            </div>
        </div>
    );
};

export default ModalInfo;