import React from 'react';
import {
    NavLink,
    Navigate,
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    TitleBackground
} from '../../components';

const Brand = () => {
    const { brand } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }

            const sliderProductThumbnails = new window.Swiper('.sliderProductThumbnails', {
                spaceBetween: 8,
                slidesPerView: 6,
                freeMode: true,
                watchSlidesProgress: true
            });
    
            const sliderProduct = new window.Swiper('.sliderProduct', {
                thumbs: { swiper: sliderProductThumbnails },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                }
            });
        }
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    let brandItem;

    state.responseBrandList.map((brands) => {
        if (brands.items.find((item) => { return item.id == brand; })) {
            brandItem = brands.items.find((item) => {
                return item.id == brand;
            });
        }
    });

    const handleGameTreasureHuntIconClick = (event) => {
        event.preventDefault();
        if (!state.gameTreasureHuntStarted) {
            setState({
                ...state,
                gameTreasureHuntStarted: Date.now()
            });
            navigate('/yarisma/hazine-avi');
        }
    }

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5" data-title-background="pt">
                    <TitleBackground className="d-lg-block d-none" />
                    <div className="container">
                        <div className="d-lg-flex d-none text-white small mb-4 opacity-50" data-title-background>
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <NavLink to="/markalarimiz" className="text-white text-decoration-none me-3">Markalarımız</NavLink>
                            <span className="me-3">»</span>
                            <NavLink to={`/markalarimiz/${brandItem.brandId}`} className="text-white text-decoration-none me-3">{state.responseBrandList.find((item) => { return item.id == brandItem.brandId }).name}</NavLink>
                        </div>
                        <div className="row gy-5">
                            <div className="col-lg-5">
                                <div className="position-relative rounded-3 overflow-hidden mb-2">
                                    <div className="swiper sliderProduct bg-white border-blue-lighter">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <a href={Constants.IMAGES_BASE_URL + '/' + brandItem.icon} className="d-block" data-fancybox>
                                                    <img src={Constants.IMAGES_BASE_URL + '/' + brandItem.icon} alt="" className="w-100 img-contain" style={{width: '500px', height: '600px'}} />
                                                </a>
                                            </div>
                                            {brandItem.listImages ? (
                                                brandItem.listImages.map((item, i) => (
                                                    <div className="swiper-slide" key={i}>
                                                        <a href={Constants.IMAGES_BASE_URL + '/' + item} className="d-block" data-fancybox>
                                                            <img src={Constants.IMAGES_BASE_URL + '/' + item} alt="" className="w-100 img-contain" style={{width: '500px', height: '600px'}} />
                                                        </a>
                                                    </div>
                                                ))
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <span className="rounded-pill bg-orange text-white small position-absolute z-index-top top-0 start-0 px-3 py-1 m-sm-3 m-2">Yeni</span> */}
                                    <i className="fas fa-expand h4 mb-0 position-absolute z-index-top top-0 end-0 m-sm-3 m-2 text-gray"></i>
                                </div>
                                <div className="swiper sliderProductThumbnails">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <img src={Constants.IMAGES_BASE_URL + '/' + brandItem.icon} alt="" className="w-100 p-2 img-contain rounded-3 border-blue-lighter" style={{width: '84px', height: '84px'}} />
                                        </div>
                                        {brandItem.listImages ? (
                                            brandItem.listImages.map((item, i) => (
                                                <div className="swiper-slide" key={i}>
                                                    <img src={Constants.IMAGES_BASE_URL + '/' + item} alt="" className="w-100 p-2 img-contain rounded-3 border-blue-lighter" style={{width: '84px', height: '84px'}} />
                                                </div>
                                            ))
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 offset-0">
                                <h1 className="mb-5 text-white text-white-lg" data-title-background>
                                    {brandItem.name}
                                    {brandItem.iconSearchFind && !state.gameTreasureHuntStarted ? (
                                        <img src="../assets/img/game01.png" alt="" className="ms-4 bg-white p-1 rounded-circle shadow-lg" style={{width: '48px', height: '48px', cursor: 'pointer'}} onClick={(event) => { handleGameTreasureHuntIconClick(event) }} />
                                    ) : null}
                                </h1>
                                <div className="pt-lg-5 pt-0">
                                    {brandItem.description ? (
                                        <p className="lead mb-5">{brandItem.description}</p>
                                    ) : null}
                                    <div className="mb-5">
                                        <span className="h2 text-green mb-0 d-block fw-bold">{brandItem.price} ₺</span>
                                    </div>
                                    <div className="row row-cols-2 g-0 align-items-center small">
                                        {brandItem.listProperty ? (
                                            brandItem.listProperty.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <div className="col py-3 border-bottom">{item.propertyName}</div>
                                                    <div className="col py-3 border-bottom">{item.propertyValue}</div>
                                                </React.Fragment>
                                            ))
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-sm-10 mb-5">
                    <div className="container">
                        <h2 className="text-center mb-sm-8 mb-5">Diğer Ürünler</h2>
                        <div className="row g-sm-4 g-2">
                            {state.responseBrandList.find((item) => {
                                return item.id == brandItem.brandId 
                            }).items.sort(() => {
                                return 0.5 - Math.random()
                            }).slice(0, 4).map((item, i) => (
                                <div className="col-xl-3 col-6" key={i}>
                                    <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                                        <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 p-3" style={{width: '300px', height: '200px'}} />
                                        <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                                            <h5 className="fw-normal lh-base mb-2">{item.name}</h5>
                                            <span className="d-block small text-blue mb-4">{item.price} ₺</span>
                                            <NavLink to={`/marka/${item.id}`} className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto">
                                                İncele<i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none"></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Brand;