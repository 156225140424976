import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from '../stores';
import {
    Loading,
    Toast,
    ModalUser,
    ModalWelcome
} from '../components';

const Footer = () => {
    const [state, setState] = useContext(Context);

    return (
        <React.Fragment>
            <footer className="bg-gray-darkest">
                <div className="container">
                    <div className="py-sm-8 py-5">
                        <div className="row gy-5">
                            <div className="col-lg-4 col-md-12">
                                <a href="tel:08505325787" className="d-block text-white text-decoration-none mb-lg-5 mb-4"><i className="fas fa-phone-alt me-3"></i>0850 532 5787</a>
                                <div className="h4 mb-0 opacity-50">
                                    {/* TODO: urls */}
                                    <a href="#" target="_blank" className="text-white text-decoration-none me-sm-5 me-3"><i className="fab fa-facebook"></i></a>
                                    <a href="#" target="_blank" className="text-white text-decoration-none me-sm-5 me-3"><i className="fab fa-twitter"></i></a>
                                    <a href="#" target="_blank" className="text-white text-decoration-none me-sm-5 me-3"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#" target="_blank" className="text-white text-decoration-none"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <NavLink to="/markalarimiz" className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Markalarımız</NavLink>
                                <ul className="list-unstyled small opacity-50 d-grid gap-2">
                                    {state.responseBrandList ? (
                                        state.responseBrandList.map((item) => (
                                            <li key={item.id}>
                                                <NavLink to={`/markalarimiz/${item.id}`} className="text-white text-decoration-none">{item.name}</NavLink>
                                            </li>
                                        ))
                                    ) : null}
                                </ul>
                                <NavLink to="/bizim-dukkan" className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Bizim Dükkan</NavLink>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <span className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Yarışmalar</span>
                                <ul className="list-unstyled small opacity-50 d-grid gap-2">
                                    <li>
                                        <NavLink to="/yarisma/hazine-avi" className="text-white text-decoration-none">Hazine Avı</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/yarisma/tahmin-et" className="text-white text-decoration-none">Tahmin Et</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/yarisma/esini-bul" className="text-white text-decoration-none">Eşini Bul</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/yarisma/haftanin-enleri" className="text-white text-decoration-none">Haftanın En'leri</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <span className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Bizim Dünya</span>
                                <ul className="list-unstyled small opacity-50 d-grid gap-2">
                                    <li>
                                        <NavLink to="/bizim-dergi" className="text-white text-decoration-none">Bizim Dergi</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/bizim-tv" className="text-white text-decoration-none">Bizim TV</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/bizim-meydan" className="text-white text-decoration-none">Bizim Meydan</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <NavLink to="/icerik/faq" className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Sıkça Sorulan Sorular</NavLink>
                                <NavLink to="/icerik/sart-ve-kosullar" className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Şart ve Koşullar</NavLink>
                                <NavLink to="/icerik/kullanim-klavuzu" className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Kullanım Kılavuzu</NavLink>
                                <NavLink to="/icerik/duyurular" className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Duyurular</NavLink>
                                <NavLink to="/hesap/mesajlarim" className="d-block h6 mb-3 text-white text-decoration-none fw-normal">Bize Ulaşın</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center border-top text-white fw-light small opacity-25 py-4">
                    <div className="container">
                        <p className="my-2">Copyright © 2023 Dizayn Grup. Tüm Hakları Saklıdır.</p>
                    </div>
                </div>
            </footer>

            <Loading />
            <Toast />
            <ModalUser />
            <ModalWelcome />
        </React.Fragment>
    );
};

export default Footer;