import React from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import { Constants, Context } from '../stores';

const Header = () => {
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {
        $(window).scroll(function(){
            var y = $(window).scrollTop();
            if (y > 0) {
                $('header').addClass('scrolled');
            } else {
                $('header').removeClass('scrolled');
            }
        });
    }, []);

    const handleDismiss = () => {
        setState({
            ...state,
            announcementsDismissed: true
        });
    };

    return (
        <header className="sticky-top bg-white shadow-lg">
            {state.responseAnnouncements && !state.announcementsDismissed ? (
                state.responseAnnouncements.slice(0, 1).map((item, i) => (
                    <div className="alert alert-dismissible border-0 bg-blue-lighter text-blue-light small text-center mb-0 fade show" key={i}>
                        <i className="fas fa-info-circle me-2"></i>
                        {item.title} {item.url ? (
                            <a href={item.url} target={item.newTab ? '_blank' : '_self'} className="text-reset">{item.buttonName}</a>
                        ) : null}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={() => handleDismiss()}></button>
                    </div>
                ))
            ) : null}

            <nav className="navbar navbar-expand-xl py-3">
                <div className="container-xxl">
                    <NavLink to="/" className="navbar-logo col-xl-2">
                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" />
                    </NavLink>

                    <div className="order-xl-2 order-1 col-xl-2 ms-auto me-4">
                        <a href="#" className="d-flex justify-content-end align-items-center text-decoration-none" data-bs-toggle="modal" data-bs-target="#modalUser">
                            {state.responseLogin.employeeFirm.profilePhoto ? (
                                <img src={Constants.IMAGES_BASE_URL + '/' + state.responseLogin.employeeFirm.profilePhoto} alt="" className="shadow-sm rounded-circle img-cover" style={{width: '48px', height: '48px'}} />
                            ) : (
                                <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold" style={{width: '48px', minWidth: '48px', minHeight: '48px', height: '48px'}}>
                                    {state.responseLogin.employeeFirm.employee.name.slice(0, 1) + state.responseLogin.employeeFirm.employee.surname.slice(0, 1)}
                                </div>
                            )}
                            <div className="ms-3">
                                <h6 className="mb-0">{state.responseLogin.employeeFirm.employee.fullName}</h6>
                                <span className="text-green small fw-bold">{state.responseLogin.employeeFirm.score.score} Puan</span>
                            </div>
                        </a>
                    </div>

                    <button className="navbar-toggler collapsed order-xl-3 order-2" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>

                    <div className="collapse navbar-collapse order-xl-1 order-3 col-xl-auto">
                        <ul className="navbar-nav gap-xl-5 gap-4 mx-auto py-xl-0 py-4 pb-2">
                            <li className="nav-item">
                                <NavLink to="/markalarimiz" className="nav-link text-gray-darker p-0">Markalarımız</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/bizim-dukkan" className="nav-link text-gray-darker p-0">Bizim Dükkan</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link text-gray-darker p-0 d-flex align-items-center">Yarışmalar<i className="fas fa-caret-down small ms-2"></i></a>
                                <ul className="dropdown-menu border-0 shadow-sm rounded-3">
                                    <li>
                                        <NavLink to="/yarisma/hazine-avi" className="dropdown-item">Hazine Avı</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/yarisma/tahmin-et" className="dropdown-item">Tahmin Et</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/yarisma/esini-bul" className="dropdown-item">Eşini Bul</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/yarisma/haftanin-enleri" className="dropdown-item">Haftanın En'leri</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link text-gray-darker p-0 d-flex align-items-center">Bizim Dünya<i className="fas fa-caret-down small ms-2"></i></a>
                                <ul className="dropdown-menu border-0 shadow-sm rounded-3">
                                    <li>
                                        <NavLink to="/bizim-dergi" className="dropdown-item">Bizim Dergi</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/bizim-tv" className="dropdown-item">Bizim TV</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/bizim-meydan" className="dropdown-item">Bizim Meydan</NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;