import React from 'react';
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';
import { Context } from './stores';
import {
    Account,
    Brand,
    Brands,
    Cart,
    Comments,
    Content,
    GameBestStores,
    GameGuessIt,
    GameMatchIt,
    GameTreasureHunt,
    Home,
    Login,
    NotFound,
    Post,
    Posts,
    Product,
    Products,
    Register,
    Reset,
    Videos
} from './pages';

const App = () => {
    const [state, setState] = React.useState({});

    //handle page refresh, setState from localStorage if available
    if (Object.entries(state).length === 0) {
        if (localStorage.getItem('state')) {
            setState({
                ...JSON.parse(localStorage.getItem('state'))
            });
        }
    }

    return (
        <Context.Provider value={[state, setState]}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>
                    <Route path="*" element={<NotFound />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/reset" element={<Reset />} />
                    <Route path="/register" element={<Register />} />

                    <Route path="/" element={<Home />} />

                    <Route path="/markalarimiz" element={<Brands />} />
                    <Route path="/markalarimiz/:brandCategory" element={<Brands />} />
                    <Route path="/marka/:brand" element={<Brand />} />

                    <Route path="/bizim-dukkan" element={<Products />} />
                    <Route path="/bizim-dukkan/sayfa/:productPage" element={<Products />} />
                    <Route path="/bizim-dukkan/:productCategory" element={<Products />} />
                    <Route path="/bizim-dukkan/:productCategory/sayfa/:productCategoryPage" element={<Products />} />
                    <Route path="/urun/:product" element={<Product />} />
                    <Route path="/sepet/:product" element={<Cart />} />

                    <Route path="/yarisma/hazine-avi" element={<GameTreasureHunt />} />
                    <Route path="/yarisma/tahmin-et" element={<GameGuessIt />} />
                    <Route path="/yarisma/esini-bul" element={<GameMatchIt />} />
                    <Route path="/yarisma/haftanin-enleri" element={<GameBestStores />} />
                    <Route path="/yarisma/haftanin-enleri/:gamePage" element={<GameBestStores />} />

                    <Route path="/bizim-dergi" element={<Posts />} />
                    <Route path="/bizim-dergi/:postCategory" element={<Posts />} />
                    <Route path="/makale/:post" element={<Post />} />

                    <Route path="/bizim-tv" element={<Videos />} />
                    <Route path="/bizim-tv/:videoCategory" element={<Videos />} />

                    <Route path="/bizim-meydan" element={<Comments />} />
                    <Route path="/bizim-meydan/:commentCategory" element={<Comments />} />

                    <Route path="/icerik/:contentName" element={<Content />} />
                    <Route path="/hesap/:contentName" element={<Account />} />
                </Routes>
            </BrowserRouter>
        </Context.Provider>
    );
};

export default App;