import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Constants, Context } from '../../stores';
import { LoginFooter, Toast } from '../../components';

const Register = () => {
    const navigate = useNavigate();
    const [state, setState] = React.useContext(Context);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (Object.fromEntries(new FormData(event.target)).password !== Object.fromEntries(new FormData(event.target)).passwordConfirm) {
            window.showToast('Girilen şifreler aynı olmalıdır.');
            return;
        }
        setState({
            ...state,
            isLoading: true
        });
        fetch(Constants.API_BASE_URL + '/public/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Object.fromEntries(new FormData(event.target)))
        })
        .then(response => response.json())
        .then(response => {
            setState({
                ...state,
                isLoading: false
            });
            if (response['errors']) {
                window.showToast(response['errors'][0]['message']);
            } else {
                window.showToast('Hesabınız oluşturuldu.');
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    return (
        <React.Fragment>
            <section className="bg-gray-lightest min-vh-100">
                <form onSubmit={(event) => handleSubmit(event)}>
                    <div className="container">
                        <div className="row justify-content-center py-md-8 py-5">
                            <div className="col-xl-4 col-lg-6 col-md-8 col-10">
                                <div className="text-center mb-6">
                                    <img src="../assets/img/logo.png" alt="" />
                                </div>
                                <h5 className="lh-base text-center fw-normal mb-5">Lütfen üye olmak için gerekli alanları doldurunuz.</h5>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="name" className="form-control border-0 rounded-3 px-4" placeholder="Üye Adı" defaultValue="" required />
                                    <label className="px-4">Üye Adı</label>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="surname" className="form-control border-0 rounded-3 px-4" placeholder="Üye Soyadı" defaultValue="" required />
                                    <label className="px-4">Üye Soyadı</label>
                                </div>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-xl-6 col-lg-8 col-md-12 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="mobileNo" className="form-control border-0 rounded-3 px-4" placeholder="Telefon Numarası" defaultValue="" minLength="10" maxLength="11" required />
                                    <label className="px-4">Telefon Numarası</label>
                                </div>
                            </div>
                            <div className="w-100"></div>


                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="vendorERCCode" className="form-control border-0 rounded-3 px-4" placeholder="Kullanıcı Adı" defaultValue="" required />
                                    <label className="px-4">Kullanıcı Adı</label>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="vendorName" className="form-control border-0 rounded-3 px-4" placeholder="Bayi Tabela Adı" defaultValue="" required />
                                    <label className="px-4">Bayi Tabela Adı</label>
                                </div>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="tapdkCode" className="form-control border-0 rounded-3 px-4" placeholder="Satış Belgesi No" defaultValue="" required />
                                    <label className="px-4">Satış Belgesi No</label>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <select className="form-select border-0 rounded-3 px-4" name="tapdkType" defaultValue="" required>
                                        <option>Seçiniz</option>
                                        <option value="P">P</option>
                                        <option value="PT">PT</option>
                                    </select>
                                    <label className="px-4">Satış Belgesi Tipi</label>
                                </div>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="password" className="form-control border-0 rounded-3 px-4" placeholder="Şifre" defaultValue="" maxLength="8" required />
                                    <label className="px-4">Şifre</label>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-10">
                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="passwordConfirm" className="form-control border-0 rounded-3 px-4" placeholder="Şifre (Tekrar)" defaultValue="" maxLength="8" required />
                                    <label className="px-4">Şifre (Tekrar)</label>
                                </div>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-xl-6 col-lg-8 col-md-12 col-10">
                                <div className="form-check my-4">
                                    <input className="form-check-input" type="checkbox" value="" id="registerConfirm" required />
                                    <label className="form-check-label fw-light small" htmlFor="registerConfirm">Kişisel Veriler Hakkında Bildirim ve Aydınlatma Metni’ni okudum, anladım ve kabul ediyorum.</label>
                                </div>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-xl-6 col-lg-8 col-md-12 col-10">
                                <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4" disabled={state.isLoading}>
                                    {state.isLoading ? (<span className="spinner-border spinner-border-sm me-3"></span>) : null}
                                    Üye Ol<i className="fas fa-arrow-right ms-3"></i>
                                </button>
                                <div className="text-center text-gray small">
                                    <NavLink to="/login">Giriş Yap</NavLink> veya <NavLink to="/reset">Şifremi Unuttum</NavLink>
                                </div>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-xl-4 col-lg-6 col-md-8 col-10">
                                <LoginFooter />
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <Toast />
        </React.Fragment>
    );
};

export default Register;