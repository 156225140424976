import React from 'react';
import {
    NavLink,
    Navigate,
    useLocation,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    Pagination
} from '../../components';

const Products = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { productPage, productCategory, productCategoryPage } = useParams();
    const [state, setState] = React.useContext(Context);
    const [pageState, setPageState] = React.useState({
        filter: false,
        sort: 'ASC',
    });
    const pagePer = 12;
    let pageTotal;
    let subCategory;

    React.useEffect(() => {

        //check expireSecond
        if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
            localStorage.clear();
            setState({
                ...state,
                isAuthorized: false
            });
            navigate('/login');
        }
    }, []);

    // React.useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    if (productCategory) {
        if (!productCategoryPage) {
            return <Navigate to={`/bizim-dukkan/${productCategory}/sayfa/1`} />
        }
    } else {
        if (!productPage) {
            return <Navigate to={`/bizim-dukkan/sayfa/1`} />
        }
    }

    const handleFilterChange = () => {
        setPageState({
            ...pageState,
            filter: pageState.filter === false ? true : false
        });
    };

    const handleSortChange = (event) => {
        setPageState({
            ...pageState,
            sort: event.target.value
        });
    };

    const listProducts = (paged) => {
        let result = state.responseProducts;

        if (productCategory) {
            result = result.filter((item) => {
                return item.subCategoryId == productCategory;
            });
        }

        if (pageState.filter) {
            result = result.filter((item) => {
                return item.score <= state.responseLogin.employeeFirm.score.score;
            });
        }

        if (pageState.sort == 'DESC') {
            result = result.reverse();
        }

        if (paged === false) {
            return result;
        }

        if (productPage) {
            result = result.slice(pagePer * (productPage - 1), (pagePer * (productPage - 1)) + pagePer)
        }

        if (productCategoryPage) {
            result = result.slice(pagePer * (productCategoryPage - 1), (pagePer * (productCategoryPage - 1)) + pagePer)
        }

        return result;
    };

    state.responseProductCategories.map((category) => {
        category.subCategoryList.find((item) => {
            if (item.id == productCategory) {
                subCategory = item;
            }
        });
    });

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="bg-blue py-5 mb-sm-5 mb-3">
                    <div className="container">
                        <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <NavLink to="/bizim-dukkan" className="text-white text-decoration-none me-3">Bizim Dükkan</NavLink>
                            <span className="me-3">»</span>
                            {productCategory ? (
                                <NavLink to={`/bizim-dukkan/${subCategory.id}`} className="text-white text-decoration-none me-3">{subCategory.name}</NavLink>
                            ) : (
                                <NavLink to="/bizim-dukkan" className="text-white text-decoration-none me-3">Tüm Ürünler</NavLink>
                            )}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <h1 className="mb-0 text-white">Bizim Dükkan</h1>
                            <div className="form-select-custom ms-3">
                                <select className="form-select w-auto border-0 text-end bg-transparent text-white p-0 text-truncate" defaultValue={pageState.sort} onChange={(event) => { handleSortChange(event); }}>
                                    <option value="ASC">Puana göre artan</option>
                                    <option value="DESC">Puana göre azalan</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-sm-10 mb-5">
                    <div className="container">
                        <div className="row gy-0">
                            <div className="col-xl-3 col-lg-4">
                                <div className="pe-lg-5 pe-0">
                                    <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                                        <div className="offcanvas-header">
                                            <h5 className="offcanvas-title">Kategoriler</h5>
                                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div className="offcanvas-body">
                                            <div className="bg-orange rounded-3 p-4 mb-5">
                                                <h1 className="text-white text-center mb-3">{state.responseLogin.employeeFirm.score.score}</h1>
                                                <div className="form-check form-switch d-flex align-items-center ps-0">
                                                    <label className="form-check-label small me-2 text-white opacity-50" htmlFor="filter">Puan ile alabilceğim ürünleri görüntüle</label>
                                                    <input className="form-check-input flex-shrink-0 float-none m-0" type="checkbox" id="filter" defaultChecked={pageState.filter} onChange={() => { handleFilterChange(); }} />
                                                </div>
                                            </div>

                                            <div className="accordion accordion-flush" id="productCategories">
                                                <div className="py-2 border-0">
                                                    <NavLink to="/bizim-dukkan" className="text-decoration-none">Tüm Ürünler</NavLink>
                                                </div>
                                                {state.responseProductCategories.map((item, i) => (
                                                    <div className="accordion-item py-2 border-0" key={i}>
                                                        <a href="#" className={`accordion-button text-decoration-none p-0 text-blue bg-transparent border-0 shadow-none${item.subCategoryList.filter((item) => { return item.id == productCategory; }).length > 0 ? '' : ' collapsed'}`} data-bs-toggle="collapse" data-bs-target={`#productCategory${item.id}`}>{item.name}</a>
                                                        <div className={`accordion-collapse collapse${item.subCategoryList.filter((item) => { return item.id == productCategory; }).length > 0 ? ' show' : ''}`} data-bs-parent="#productCategories" id={`productCategory${item.id}`}>
                                                            <ul className="list-unstyled d-grid gap-3 opacity-75 small mt-3 ms-3 mb-0">
                                                                {item.subCategoryList.map((item, i) => (
                                                                    <li key={i}>
                                                                        <NavLink to={`/bizim-dukkan/${item.id}`} className="text-decoration-none">{item.name}</NavLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <a href=".offcanvas" className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3" data-bs-toggle="offcanvas">
                                        <i className="fas fa-filter"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8">
                            {listProducts().length > 0 ? (
                                <React.Fragment>
                                    <div className="row g-sm-4 g-2">
                                        {listProducts().map((item, i) => (
                                            <div className="col-xl-4 col-6" key={i}>
                                                <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                                                    <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 p-3" style={{width: '300px', height: '200px'}} />
                                                    <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                                                        <span className="d-block small text-blue mb-2">{item.stock} Stok</span>
                                                        <h5 className="fw-normal lh-base mb-4">{item.name}</h5>
                                                        <NavLink to={`/urun/${item.id}`} className="btn btn-progress text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto">
                                                            <span className="position-relative">{item.score} Puan<i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none"></i></span>
                                                            <span style={{width: Math.round((state.responseLogin.employeeFirm.score.score / item.score) * 100) + '%'}}></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mt-sm-8 mt-5">
                                        <Pagination 
                                            pageTotal={listProducts(false).length}
                                            pageActive={productPage ? productPage : (productCategoryPage ? productCategoryPage : null)}
                                            pagePer={pagePer}
                                            pagePath={'/bizim-dukkan' + (productCategory ? '/' + productCategory : '') +'/sayfa'}
                                        />
                                    </div>
                                </React.Fragment>
                                ) : (
                                    <div className="text-center p-8">
                                        <h1 className="mb-4 opacity-75"><i className="fas fa-sad-tear"></i> Üzgünüz.</h1>
                                        <h3 className="opacity-50">Belirlediğiniz kriterlere karşılık gelen bir hediye bulunmamaktadır.</h3>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </React.Fragment>
    );
};

export default Products;