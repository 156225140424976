import React from 'react';
import {
    NavLink,
    Navigate,
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import $ from 'jquery';
import { Constants, Context } from '../../stores';
import { Footer, Header } from '../../components';

const Account = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { contentName } = useParams();
    const [state, setState] = React.useContext(Context);
    const [pageState, setPageState] = React.useState({
        responseAddresses: false,
        provinces: false,
        districts: false,
        isNewAddressVisible: false,
        editAddress: false,
        newMessageLengthLeft: 1000
    });
    let content;

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }

            Promise.all([
                fetchMessages(),
                fetchAddresses(),
                fetchProvinces(),
                fetchOrders(),
                fetchPhotos()

            ]).then((values) => {
                setPageState({
                    ...pageState,
                    responseMessages: values[0],
                    responseAddresses: values[1],
                    provinces: values[2],
                    responseOrders: values[3],
                    responsePhotos: values[4]
                });
            });
        }
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
    });

    const fetchPhotos = () => {
        return fetch(Constants.API_BASE_URL + '/employeePhotoList/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => { return response['data']; });
    };

    const fetchOrders = () => {
        return fetch(Constants.API_BASE_URL + '/orderList/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => { return response['data']; });
    };

    const fetchMessages = () => {
        return fetch(Constants.API_BASE_URL + '/contactSubjectList/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => { return response['data']; });
    };

    const fetchAddresses = () => {
        return fetch(Constants.API_BASE_URL + '/address/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => { return response['data']; });
    };

    const fetchProvinces = () => {
        return fetch(Constants.API_BASE_URL + '/address/province/TÜRKİYE', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => { return response['data']; });
    };

    const fetchDistricts = (province) => {
        if (province) {
            fetch(Constants.API_BASE_URL + '/address/county/' + province, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                setPageState({
                    ...pageState,
                    districts: response['data']
                });
            });
        } else {
            setPageState({
                ...pageState,
                districts: false
            });
        }
    };

    const handlePasswordChangeSubmit = (event) => {
        event.preventDefault();

        if (Object.fromEntries(new FormData(event.target)).password !== Object.fromEntries(new FormData(event.target)).confirmPassword) {
            window.showToast('Girilen şifreler aynı olmalıdır.');
            return;
        }

        if (!new RegExp("^[a-zA-Z0-9 ]+$").test(Object.fromEntries(new FormData(event.target)).password)) {
            window.showToast('Şifre özel karakter içeremez.');
            return;
        }

        fetch(Constants.API_BASE_URL + '/public/changePassword/' + state.responseLogin.token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify(Object.fromEntries(new FormData(event.target)))
        })
        .then(response => response.json())
        .then(response => {
            if (response['data']) {
                window.showToast('Şifreniz değiştirildi.');
                setTimeout(() => {
                    setState({
                        ...state,
                        isAuthorized: false
                    });
                    window.location.reload();
                }, 2000);
            }
        });
    };

    const handleNewAddressSubmit = (event) => {
        event.preventDefault();

        fetch(Constants.API_BASE_URL + '/address/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify(Object.fromEntries(new FormData(event.target)))
        })
        .then(response => response.json())
        .then(response => {
            if (response['data'] && response['data']['success']) {
                Promise.all([
                    fetchAddresses()
                ]).then((values) => {
                    setPageState({
                        ...pageState,
                        isNewAddressVisible: false,
                        responseAddresses: values[0],
                    });
                    window.showToast(pageState.editAddress ? 'Adres düzenlendi.' : 'Yeni adres eklendi.');
                });
            } else {
                window.showToast(response['data']['message']);
            }
        });
    };

    const handleRemoveAddressClick = (event, id) => {
        event.preventDefault();

        fetch(Constants.API_BASE_URL + '/address/delete/' + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then(response => response.json())
        .then(response => {
            if (response['data']) {
                Promise.all([
                    fetchAddresses()
                ]).then((values) => {
                    setPageState({
                        ...pageState,
                        responseAddresses: values[0],
                    });
                    window.showToast('Adres silindi.');
                });
            }
        });
    };

    const handleUpdateAddressClick = (event, id) => {
        event.preventDefault();

        pageState.responseAddresses.map((item, i) => {
            if (item.addressId == id) {
                setPageState({
                    ...pageState,
                    isNewAddressVisible: true,
                    editAddress: {
                        name: item.name,
                        addressId: item.addressId
                    }
                });
            }
        });
    };

    const handleUpdateOrderAddressClick = (event, id) => {
        event.preventDefault();

        fetch(Constants.API_BASE_URL + '/address/orderAddress/' + id + '/' + state.responseLogin.employeeFirm.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify({
                addressId: id,
                employeeFirmId: state.responseLogin.employeeFirm.id
            })
        })
        .then(response => response.json())
        .then(response => {
            if (response['data']) {
                Promise.all([
                    fetchAddresses()
                ]).then((values) => {
                    setPageState({
                        ...pageState,
                        responseAddresses: values[0],
                    });
                    window.showToast('Sipariş adresi güncellendi.');
                });
            }
        });
    };

    const handleNewMessageChange = (event) => {
        setPageState({
            ...pageState,
            newMessageLengthLeft: 1000 - (event.target.value).length
        });
    };

    const handleNewMessageSubmit = async (event, id) => {
        event.preventDefault();

        setState({
            ...state,
            isLoading: true
        });

        fetch(Constants.API_BASE_URL + '/' + (id ? 'contactMessage' : 'contactMessageInitial') + '/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify({
                ... id ? { contactSubjectId: id } : { contactSubjectText: Object.fromEntries(new FormData(event.target)).contactSubjectText, },
                imgSrc: await toBase64(Object.fromEntries(new FormData(event.target)).imgSrc),
                message: Object.fromEntries(new FormData(event.target)).message,
                senderEmployeeFirmId: parseInt(state.responseLogin.employeeFirm.id)
            })
        })
        .then((response) => {
            if (response.status == 200) {
                event.target.reset();
                window.showToast('Mesajınız gönderildi.');

                setTimeout(() => {
                    Promise.all([
                        fetchMessages()
                    ]).then((values) => {
                        setState({
                            ...state,
                            isLoading: false
                        });
                        setPageState({
                            ...pageState,
                            responseMessages: values[0]
                        });
                    });
                }, 100)
            }
        });
    };

    const handleMessageClick = (event, id) => {
        if (!$(event.target).hasClass('collapsed')) {
            fetch(Constants.API_BASE_URL + '/contactMessageList/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            });
        }
    };

    const handleImageChange = (event) => {
        event.preventDefault();
        if (event.target.files?.[0]) {
            setPageState({
                ...pageState,
                newImage: window.URL.createObjectURL(event.target.files[0])
            });
        }
    };

    const handleImageRemove = (event) => {
        event?.preventDefault();
        $('input[type="file"]').val(null);
        setPageState({
            ...pageState,
            newImage: false
        });
    };

    const handleNewPhotoSubmit = async (event) => {
        event.preventDefault();
        setState({
            ...state,
            isLoading: true
        });
        fetch(Constants.API_BASE_URL + '/uploadPhoto', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify({
                employeeFirmId: state.responseLogin.employeeFirm.id,
                imgSrc: await toBase64(Object.fromEntries(new FormData(event.target)).file),
                selectedChoice: Object.fromEntries(new FormData(event.target)).type == 'type1' ? 'PROFILE' : 'SHOP',
                usePermission: Object.fromEntries(new FormData(event.target)).permission ? 'true' : null
            })
        })
        .then((response) => {
            if (response.status == 200) {
                setState({
                    ...state,
                    isLoading: false
                });
                event.target.reset();
                handleImageRemove();
                window.showToast('Fotoğrafınız onay için gönderildi.');
            }
        });
    }

    switch (contentName) {
        case 'profilim':
            content = (
                <React.Fragment>
                    <h2 className="mb-sm-6 mb-4">Hesap Bilgilerim</h2>
                    <p className="mb-sm-5 mb-4 fw-light">Aşağıdaki bilgilerde değişiklik yapabilmek için 0850 532 5 584 numaralı Çağrı Merkezi’mizi arayabilir, Satış Belgesi numaranızı ve bayi kodunuzu kullanarak müşteri temsilcimizle görüşebilirsiniz. Şifre değiştirme bölümü aracılığıyla şifrenizi değiştirebilirsiniz.</p>
                    <div className="row gy-5 row-cols-md-3 row-cols-2 pb-sm-5 pb-4 border-bottom mb-sm-5 mb-4">
                        {state.responseLogin.employeeFirm.vendorName ? (
                            <div className="col">
                                <h6 className="opacity-50">Bayi Tabela Adı</h6>
                                <p className="mb-0">{state.responseLogin.employeeFirm.vendorName}</p>
                            </div>
                        ) : null}
                        {state.responseLogin.employeeFirm.employee.name ? (
                            <div className="col">
                                <h6 className="opacity-50">Üye Adı</h6>
                                <p className="mb-0">{state.responseLogin.employeeFirm.employee.name}</p>
                            </div>
                        ) : null}
                        {state.responseLogin.employeeFirm.employee.surname ? (
                            <div className="col">
                                <h6 className="opacity-50">Üye Soyadı</h6>
                                <p className="mb-0">{state.responseLogin.employeeFirm.employee.surname}</p>
                            </div>
                        ) : null}
                        {state.responseLogin.employeeFirm.tapdkFull ? (
                            <div className="col">
                                <h6 className="opacity-50">Satış Belgesi Numarası</h6>
                                <p className="mb-0">{state.responseLogin.employeeFirm.tapdkFull}</p>
                            </div>
                        ) : null}
                        {state.responseLogin.employeeFirm.ercCode ? (
                            <div className="col">
                                <h6 className="opacity-50">Kullanıcı Adı</h6>
                                <p className="mb-0">{state.responseLogin.employeeFirm.ercCode}</p>
                            </div>
                        ) : null}
                        {state.responseLogin.employeeFirm.employee.mobileNo ? (
                            <div className="col">
                                <h6 className="opacity-50">Cep Telefonu</h6>
                                <p className="mb-0">{state.responseLogin.employeeFirm.employee.mobileNo}</p>
                            </div>
                        ) : null}
                    </div>
                    <h5 className="text-blue mb-sm-5 mb-4">Şifre Değiştirme</h5>
                    <form onSubmit={(event) => handlePasswordChangeSubmit(event)}>
                        <div className="row gy-3 row-cols-md-3 row-cols-1 align-items-center">
                            <div className="col">
                                <div className="form-floating">
                                    <input type="password" className="form-control" placeholder="Yeni Şifre" name="password" minLength="4" maxLength="8" required />
                                    <label>Yeni Şifre</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating">
                                    <input type="password" className="form-control" placeholder="Yeni Şifre" name="confirmPassword" minLength="4" maxLength="8" required />
                                    <label>Yeni Şifre (Tekrar)</label>
                                </div>
                            </div>
                            <div className="col">
                                <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100">Değiştir</button>
                            </div>
                        </div>
                    </form>
                </React.Fragment>
            );
            break;

        case 'adreslerim':
            content = (
                <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between mb-sm-6 mb-4">
                        <h2 className="mb-0">Adres Bilgilerim</h2>
                        {!pageState.isNewAddressVisible && pageState.responseAddresses ? (
                            <button type="button" className="btn btn-link text-decoration-none" onClick={(event) => {
                                setPageState({
                                    ...pageState,
                                    isNewAddressVisible: true,
                                    editAddress: false
                                })
                            }}>
                                <i className="fas fa-plus-circle"></i> Yeni Adres Ekle
                            </button>
                        ) : null}
                    </div>
                    <p className="mb-sm-5 mb-4 fw-light">Adres alanına girdiğiniz bilgiler, hediye siparişlerinizin gönderimi için kullanılacağından bilgilerinizin doğruluğundan lütfen emin olunuz. Ev ya da işyeri adresi gibi birden fazla adres girişi yapabilirsiniz. Yalnızca siparişinizin gönderilmesini istediğiniz adresi "Sipariş Adresi" olarak seçmeniz gerekmektedir. Sipariş vermeden önce mutlaka doğru adresin sipariş adresi olarak seçildiğini kontrol etmelisiniz.</p>

                    {pageState.isNewAddressVisible || !pageState.responseAddresses ? (
                        <form onSubmit={(event) => handleNewAddressSubmit(event)}>
                            <div className="d-flex align-items-center justify-content-between mb-sm-5 mb-4">
                                <h5 className="text-blue mb-0">{pageState.editAddress ? 'Adresi Düzenle' : 'Yeni Adres Ekle'}</h5>
                                <button type="button" className="btn btn-link text-red text-decoration-none" onClick={(event) => {
                                    setPageState({
                                        ...pageState,
                                        isNewAddressVisible: false,
                                        editAddress: false
                                    })
                                }}>
                                    {(!pageState.isNewAddressVisible && pageState.responseAddresses) || (pageState.isNewAddressVisible && pageState.responseAddresses) ? (
                                        <small><i className="fas fa-times me-2"></i>Vazgeç</small>
                                    ) : null}
                                </button>
                            </div>
                            <div className="row row-cols-md-3 row-cols-1 gy-4 pb-sm-5 pb-4 mb-sm-5 mb-4">
                                <div className="col">
                                    <div className="form-floating">
                                        <input type="text" name="name" className="form-control" placeholder="Adres Adı" maxLength="20" required 
                                            defaultValue={pageState.editAddress && pageState.editAddress.name ? pageState.editAddress.name : ''} 
                                        />
                                        <label>Adres Adı</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <select name="province" className="form-select" required onChange={(event) => { fetchDistricts(event.target.value); }}>
                                            <option value="">Seçiniz</option>
                                            {pageState.provinces ? (
                                                pageState.provinces.map((item, i) => (
                                                    <option value={item.name} key={i}>{item.name}</option>
                                                ))
                                            ) : null}
                                        </select>
                                        <label>Şehir</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <select name="county" className="form-select" required disabled={pageState.districts ? false : true}>
                                            <option value="">Seçiniz</option>
                                            {pageState.districts ? (
                                                pageState.districts.map((item, i) => (
                                                    <option value={item.name} key={i}>{item.name}</option>
                                                ))
                                            ) : null}
                                        </select>
                                        <label>İlçe</label>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="form-floating">
                                        <input type="text" name="district" className="form-control" placeholder="Mahalle" maxLength="100" required />
                                        <label>Mahalle</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <input type="text" name="street" className="form-control" placeholder="Cadde/Sokak" maxLength="100" required />
                                        <label>Cadde/Sokak</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <input type="text" name="apartmentNo" className="form-control" placeholder="Apartman No/Adı" maxLength="100" required />
                                        <label>Apartman No/Adı</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <input type="text" name="flatNo" className="form-control" placeholder="Apartman No/Adı" maxLength="100" required />
                                        <label>Daire No</label>
                                    </div>
                                </div>

                                <div className="col">
                                    <input type="hidden" name="addressId" defaultValue={pageState.editAddress && pageState.editAddress.addressId ? pageState.editAddress.addressId : ''} />
                                    <input type="hidden" name="employeeFirmId" defaultValue={state.responseLogin.employeeFirm.id} />
                                    <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100">
                                        {pageState.editAddress ? 'Düzenle' : 'Ekle'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : null}

                    {!pageState.isNewAddressVisible && pageState.responseAddresses ? (
                        <React.Fragment>
                            <h5 className="text-blue mb-sm-5 mb-4">Adreslerim</h5>
                            <div className="row row-cols-xl-3 row-cols-md-2 row-cols-1 gy-4">
                                {pageState.responseAddresses.map((item, i) => (
                                    <div className="col" key={item.addressId}>
                                        <div className="h-100 bg-white shadow-lg rounded-3 p-4 position-relative">
                                            <h6 className="text-capitalize text-truncate" style={{maxWidth: '90%'}}>{item.name}</h6>
                                            <p className="mb-0">{item.countyName} / {item.provinceName}</p>
                                            <div className="mt-4 d-flex align-items-center">
                                                <a href="#" className="text-decoration-none small me-3" onClick={(event) => handleUpdateAddressClick(event, item.addressId)}><i className="fas fa-edit me-2"></i>Düzenle</a>
                                                {JSON.parse(item.orderAddress.toLowerCase()) ? (
                                                    <span className="small text-green ms-auto"><i className="fas fa-check-circle me-2"></i>Sipariş Adresi</span>
                                                ) : (
                                                    <a href="#" className="text-decoration-none small ms-auto" onClick={(event) => handleUpdateOrderAddressClick(event, item.addressId)}><i className="fas fa-circle fw-light me-2"></i>Sipariş Adresi</a>
                                                )}
                                            </div>
                                            <button type="button" className="btn btn-link text-decoration-none text-red position-absolute top-0 end-0 m-2" onClick={(event) => handleRemoveAddressClick(event, item.addressId)}>
                                                <i className="fas fa-trash-alt small"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            );
            break;

        case 'mesajlarim':
            content = (
                <React.Fragment>
                    <h2 className="mb-sm-6 mb-4">Mesajlarım</h2>

                    <h5 className="text-blue mb-sm-5 mb-4">Bize Ulaşın</h5>
                    <form className="mb-sm-6 mb-4" onSubmit={(event) => handleNewMessageSubmit(event)}>
                        <div className="row g-5">
                            <div className="col-md-6 order-md-0 order-1">
                                <div className="form-floating mb-4">
                                    <select className="form-select" name="contactSubjectText" required defaultValue="">
                                        <option value="">Seçiniz</option>
                                        <option value="Genel">Genel</option>
                                        <option value="Şikayet/Bizim Dükkan">Şikayet/Bizim Dükkan</option>
                                        <option value="Şikayet/Yarışmalar">Şikayet/Yarışmalar</option>
                                        <option value="Şikayet/Puan Kazanımı ">Şikayet/Puan Kazanımı</option>
                                        <option value="Şikayet/Diğer">Şikayet/Diğer</option>
                                        <option value="Öneri/Bizim Dükkan">Öneri/Bizim Dükkan</option>
                                        <option value="Öneri/Yarışmalar">Öneri/Yarışmalar</option>
                                        <option value="Öneri/Puan Kazanımı">Öneri/Puan Kazanımı</option>
                                        <option value="Öneri/Diğer">Öneri/Diğer</option>
                                        <option value="Bilgi">Bilgi</option>
                                        <option value="Memnuniyet">Memnuniyet</option>
                                    </select>
                                    <label>Konu</label>
                                </div>
                                <div className="mb-2">
                                    <textarea className="form-control" placeholder="Mesajınız" style={{height: '100px'}} name="message" maxLength="1000" onChange={(event) => handleNewMessageChange(event)} required></textarea>
                                </div>
                                <span className={`small d-block text-end mb-4${pageState.newMessageLengthLeft <= 100 ? ' text-red' : ''}`}>{pageState.newMessageLengthLeft} karakter kaldı.</span>
                                <div className="mb-4">
                                    <label className="form-label">Dosya / Fotoğraf Ekleyin</label>
                                    <input className="form-control" type="file" name="imgSrc" />
                                </div>
                                <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100" disabled={!state.isLoading ? false : true}>
                                    {state.isLoading ? (<span className="spinner-border spinner-border-sm me-3"></span>) : null}
                                    Gönder
                                </button>
                            </div>
                            <div className="col-md-6 order-md-1 order-0">
                                <p className="mb-0 fw-light lh-lg">Sizi dinliyoruz. Her türlü görüş, öneri ve isteklerinizi Bize Ulaşın bölümü aracılığıyla bizimle paylaşabilirsiniz. Bizim Kahramanlar ile ilgili sorularınız için 0850 532 5 584 numaralı Çağrı Merkezimizi arayarak hafta içi 09.00-18.00 saatleri arasında bize ulaşabilirsiniz.</p>
                            </div>
                        </div>
                    </form>

                    <h5 className="text-blue mb-sm-5 mb-4">Tüm Mesajlarım</h5>
                    {pageState.responseMessages ? (
                        <div className="accordion accordion-flush shadow-lg rounded-3 overflow-hidden mb-5">
                            {pageState.responseMessages.map((item, i) => (
                                <div className="accordion-item" key={i}>
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed lh-lg d-flex" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} onClick={(event) => handleMessageClick(event, item.id)}>
                                            <span className="small opacity-75">{item.dateBeginString}</span>
                                            <span className="ms-4">{item.subject}</span>
                                            {item.unReadListMessages ? (
                                                <div className="bg-orange text-white px-3 py-0 ms-3 rounded-pill small">
                                                    {item.unReadListMessages.length}
                                                </div>
                                            ) : null}
                                        </button>
                                    </h2>
                                    <div className="accordion-collapse collapse" data-bs-parent=".accordion" id={`collapse${i}`}>
                                        <div className="accordion-body fw-light p-4 d-flex flex-column">
                                            {item.listMessage.map((message, i) => (
                                                <React.Fragment key={i}>
                                                    <div className={`px-3 py-2 mb-2 rounded-3 ${message.createdUser == state.responseLogin.employeeFirm.ercCode ? 'ms-auto bg-green text-white' : 'me-auto bg-gray-lighter'}`}>
                                                        {message.icon ? (
                                                            <div className="my-2">
                                                                <img src={Constants.IMAGES_BASE_URL + '/' + message.icon} alt="" className="rounded-3" style={{maxWidth: '100%'}} />
                                                            </div>
                                                        ) : null}
                                                        {message.message}
                                                    </div>
                                                    <div className={`mb-3 opacity-50 ${message.createdUser == state.responseLogin.employeeFirm.ercCode ? 'ms-auto' : 'me-auto'}`} style={{fontSize: '12px'}}>
                                                        {message.createDate}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                            {item.contactStatu == 'OPEN' ? (
                                                <div className="bg-gray-lightest rounded-3 p-4">
                                                    <h5 className="mb-4 text-blue">Cevapla</h5>
                                                    <form onSubmit={(event) => handleNewMessageSubmit(event, item.id)}>
                                                        <div className="mb-3">
                                                            <textarea className="form-control" placeholder="Mesajınız" style={{height: '100px'}} name="message" maxLength="1000" required></textarea>
                                                        </div>
                                                        <div className="mb-4 d-flex align-items-center">
                                                            <label className="form-label mb-0 me-4 text-nowrap small"><strong>Dosya / Fotoğraf Ekleyin</strong></label>
                                                            <input className="form-control" type="file" name="imgSrc" />
                                                        </div>
                                                        <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100" disabled={!state.isLoading ? false : true}>
                                                            {state.isLoading ? (<span className="spinner-border spinner-border-sm me-3"></span>) : null}
                                                            Gönder
                                                        </button>
                                                    </form>
                                                </div>
                                            ) : (
                                                <div className="text-center small py-4 text-red">
                                                    Bu mesajınızın kapatılmıştır. Yeni bir mesaj başlatarak bize ulaşabilirsiniz.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center">
                            <h2 className="mb-4 opacity-75">Hiç Mesajınız yok</h2>
                        </div>
                    )}
                </React.Fragment>
            );
            break;

        case 'fotograflarim':
            content = (
                <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between mb-sm-6 mb-4">
                        <h2 className="mb-0">Fotoğraflarım</h2>
                        {!pageState.isNewPhotoVisible ? (
                            <button type="button" className="btn btn-link text-decoration-none" onClick={(event) => {
                                setPageState({
                                    ...pageState,
                                    isNewPhotoVisible: true,
                                })
                            }}>
                                <i className="fas fa-plus-circle"></i> Yeni Fotoğraf Ekle
                            </button>
                        ) : null}
                    </div>
                    <p className="mb-sm-5 mb-4 fw-light">
                        Bu bölümde profil ve dükkan fotoğraflarınızı ekleyebilir veya güncelleyebilirsiniz.
                        Profil fotoğrafınızın onaylanabilmesi için, lütfen yüzünüzün net olarak göründüğü bir fotoğraf seçin. Dükkan fotoğrafınızın onaylanabilmesi içinse, dükkanınızın içeriden veya dışarıdan geniş açı ile çekilmiş bir fotoğrafını yükleyin.
                        Yüklediğiniz fotoğraflar için onay mesajını bir hafta içinde Mesajlarım bölümünde görebilirsiniz. Fotoğrafınız ile ilgili mesaj almadığınız takdirde “Bize Ulaşın” formunu doldurarak veya 0850 532 5 584 Bizim Kahramanlar Çağrı Merkezi’ni arayarak detaylı bilgi alabilirsiniz.
                    </p>

                    {pageState.isNewPhotoVisible ? (
                        <form onSubmit={(event) => handleNewPhotoSubmit(event)}>
                            <div className="d-flex align-items-center justify-content-between mb-sm-5 mb-4">
                                <h5 className="text-blue mb-0">Yeni Fotoğraf Ekle</h5>
                                <button type="button" className="btn btn-link text-red text-decoration-none" onClick={(event) => {
                                    setPageState({
                                        ...pageState,
                                        isNewPhotoVisible: false
                                    })
                                }}>
                                    <small><i className="fas fa-times me-2"></i>Vazgeç</small>
                                </button>
                            </div>
                            <div className="row gy-4 gx-5 align-items-center pb-sm-5 pb-4 mb-sm-5 mb-4">
                                <div className="col">
                                    <div className="shadow-lg rounded-3 position-relative overflow-hidden w-100 d-flex align-items-center justify-content-center" style={{height: '300px'}}>
                                        <input type="file" className="position-absolute w-100 h-100 top-0 start-0 opacity-0" style={{cursor: 'pointer'}} accept="image/*" name="file" onInput={(event) => handleImageChange(event)} />
                                        {pageState.newImage ? (
                                            <React.Fragment>
                                                <img src={pageState.newImage} alt="" className="w-100 h-100 img-contain position-absolute top-0 start-0" />
                                                <button type="button" className="btn btn-link text-white bg-red rounded-circle m-3 text-decoration-none position-absolute top-0 end-0" onClick={(event) => handleImageRemove(event)}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </React.Fragment>
                                        ) : null}
                                        <div className="text-center">
                                            <h3 className="text-green"><i className="fas fa-plus-circle"></i></h3>
                                            <h5 className="text-green">Fotoğraf Yükle</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <h6 className="mb-4">Fotoğraf Türü</h6>

                                    <div onChange={(event) => {
                                        setPageState({
                                            ...pageState,
                                            newImageType: event.target.value
                                        });
                                    }}>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="type" value="type1" id="type1" defaultChecked={true} />
                                            <label className="form-check-label" htmlFor="type1">Profil Fotoğrafı</label>
                                        </div>
                                        <div className="form-check mb-4">
                                            <input className="form-check-input" type="radio" name="type" value="type2" id="type2" />
                                            <label className="form-check-label" htmlFor="type2">Dükkan Fotoğrafı</label>
                                        </div>
                                    </div>

                                    {pageState.newImageType == 'type2' ? (
                                        <div className="form-check mb-4 small">
                                            <input className="form-check-input" type="checkbox" name="permission" id="permission" value="true" />
                                            <label className="form-check-label" htmlFor="permission">Yüklediğiniz dükkan fotoğrafınızın Haftanın Dükkanı Yarışması'nda kullanılmasını ister misiniz?</label>
                                        </div>
                                    ) : null}

                                    <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3" disabled={pageState.newImage && !state.isLoading ? false : true}>
                                        {state.isLoading ? (<span className="spinner-border spinner-border-sm me-3"></span>) : null}
                                        Gönder
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : null}

                    {pageState?.responsePhotos ? (
                        <div className="row row-cols-md-3 row-cols-2 align-items-center">
                            {pageState.responsePhotos.map((item, i) => (
                                <div className="col" key={i}>
                                    <img src={Constants.IMAGES_BASE_URL + '/' + item.imageUrl} alt="" className="img-contain w-100 mb-2 rounded-3" />
                                    {item.imageStatus == 'DENIED' ? (
                                        <span className="text-red small"><i className="fas fa-times-circle me-2"></i>Reddedildi</span>
                                    ) : (
                                        item.imageStatus == 'WAITING' ? (
                                            <span className="text-orange small"><i className="fas fa-clock fw-light me-2"></i>Beklemede</span>
                                        ) : (
                                            <span className="text-green small"><i className="fas fa-check-circle fw-light me-2"></i>Onaylandı</span>
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center mt-8">
                            <h1 className="mb-3 opacity-75"><i className="fas fa-image"></i></h1>
                            <h3 className="mb-4 opacity-75">Hiç fotoğrafınız yok</h3>
                        </div>
                    )}
                </React.Fragment>
            );
            break;

        case 'puanlarim':
            const handleLoadMore = (event) => {
                event.preventDefault();
                setState({
                    ...state,
                    transactionsCount: (state.transactionsCount ? state.transactionsCount : 10) + 10
                });
            };

            content = (
                <React.Fragment>
                    <h2 className="mb-sm-6 mb-4">Puan Geçmişim</h2>
                    <p className="mb-sm-5 mb-4 fw-light">Kazandığınız puanları bu bölümde detaylı olarak görebilir, takip edebilirsiniz.</p>

                    {state.responseScoreTransactions ? (
                        state.responseScoreTransactions.slice(0, state.transactionsCount ? state.transactionsCount : 10).map((item, i) => (
                            <div className="d-flex align-items-center justify-content-between py-3 border-bottom" key={i}>
                                <span className="text-blue-light">{item.dateCreated.replaceAll('-', '.')}</span>
                                <span className="mx-4">{item.scoreTypeName}</span>
                                {item.scoreType == 'SIPARIS' ? (
                                    <span className="badge rounded-pill bg-red px-3 py-2 ms-auto">-{item.changedScore} Puan</span>
                                ) : (
                                    <span className="badge rounded-pill bg-green px-3 py-2 ms-auto">+{item.changedScore} Puan</span>
                                )}
                            </div>
                        ))
                    ) : null}

                    {state.responseScoreTransactions.length - (state.transactionsCount ? state.transactionsCount : 10) > 0 ? (
                        <div className="text-center mt-sm-6 mt-5">
                            <button type="button" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3" onClick={(event) => handleLoadMore(event)} >Daha Fazla Göster</button>
                        </div>
                    ) : null}
                </React.Fragment>
            );
            break;

        case 'siparislerim':
            content = (
                <React.Fragment>
                    <h2 className="mb-sm-6 mb-4">Sipariş Bilgilerim</h2>
                    <p className="mb-sm-5 mb-4 fw-light">Siparişlerinizin durumunu bu bölümde detaylı olarak görebilir, takip edebilirsiniz.</p>

                    {pageState?.responseOrders ? (
                        pageState?.responseOrders?.map((item, i) => (
                            <div className="border rounded-3 mb-4 p-4" key={i}>
                                <div className="row align-items-center g-4">
                                    <div className="col-md-3">
                                        <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100" />
                                    </div>
                                    <div className="col-md-6">
                                        <p className="mb-4 opacity-75" style={{fontSize: '12px'}}>Sipariş No: {item.orderNo}</p>
                                        <h6 className="mb-2">{item.productName}</h6>
                                        <p className="mb-0 fw-light opacity-75">{item.score} Puan</p>

                                        <div className="mt-4">
                                            <h6 className="mb-2 small">Sipariş Adresi: <span className="fw-light">{item.deliveryAddressName}</span></h6>
                                            <p className="small mb-0 opacity-50">{item.deliveryAddress.replace(/null /g, '')}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        {item.orderStatus == 'TESLİM EDİLDİ' ? (
                                            <React.Fragment>
                                                <h1 className="mb-3 text-green"><i className="fas fa-check-circle"></i></h1>
                                                <h6 className="text-green">Sipariş Durumu</h6>
                                                <p className="mb-0 small strong">{item.orderStatus}</p>
                                            </React.Fragment>
                                        ) : (
                                            item.orderStatus == 'KARGOYA TESLİM EDİLDİ' ? (
                                                <React.Fragment>
                                                    <h1 className="mb-3 text-blue-light"><i className="fas fa-paper-plane"></i></h1>
                                                    <h6 className="text-blue-light">Sipariş Durumu</h6>
                                                    <p className="mb-0 small strong">{item.orderStatus}</p>
                                                </React.Fragment>
                                            ) : (
                                                item.orderStatus == 'SİPARİŞİNİZ HAZIRLANIYOR' ? (
                                                    <React.Fragment>
                                                        <h1 className="mb-3 text-orange"><i className="fas fa-clock fw-light"></i></h1>
                                                        <h6 className="text-orange">Sipariş Durumu</h6>
                                                        <p className="mb-0 small strong">{item.orderStatus}</p>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <h6>Sipariş Durumu</h6>
                                                        <p className="mb-0 small strong">{item.orderStatus}</p>
                                                    </React.Fragment>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center mt-8">
                            <h1 className="mb-3 opacity-75"><i className="fas fa-shopping-basket"></i></h1>
                            <h3 className="mb-4 opacity-75">Hiç siparişiniz yok</h3>
                        </div>
                    )}
                </React.Fragment>
            );
            break;
    };

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="bg-blue py-5 mb-5">
                    <div className="container">
                        <div className="d-flex justify-content-start align-items-center text-decoration-none">
                            {state.responseLogin.employeeFirm.profilePhoto ? (
                                <img src={Constants.IMAGES_BASE_URL + '/' + state.responseLogin.employeeFirm.profilePhoto} alt="" className="shadow-sm rounded-circle img-cover" style={{width: '96px', height: '96px'}} />
                            ) : (
                                <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold" style={{width: '96px', height: '96px'}}>
                                    <i className="fas fa-user h3 mb-0"></i>
                                </div>
                            )}
                            <div className="ms-4">
                                <h2 className="mb-2 text-white">{state.responseLogin.employeeFirm.employee.fullName}</h2>
                                <span className="text-white opacity-50">{state.responseLogin.employeeFirm.score.score} Puan</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-sm-10 mb-5">
                    <div className="container">
                        <div className="row gy-0">
                            <div className="col-xl-3 col-lg-4">
                                <div className="pe-lg-5 pe-0">
                                    <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                                        <div className="offcanvas-header">
                                            <h5 className="offcanvas-title">Menü</h5>
                                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div className="offcanvas-body shadow-lg p-4 rounded-3">
                                            <ul className="list-unstyled d-grid gap-4 mb-0 overflow-hidden" data-bs-dismiss="offcanvas">
                                                <li>
                                                    <NavLink to="/hesap/profilim" className="text-decoration-none text-nowrap">
                                                        <i className="fas fa-user me-3 text-center" style={{width: '24px'}}></i>
                                                        Hesap Bilgilerim
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/hesap/adreslerim" className="text-decoration-none text-nowrap">
                                                        <i className="fas fa-map-marker-alt me-3 text-center" style={{width: '24px'}}></i>
                                                        Adres Bilgilerim
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/hesap/mesajlarim" className="text-decoration-none text-nowrap">
                                                        <i className="fas fa-user me-3 text-center" style={{width: '24px'}}></i>
                                                        Mesajlarım
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/hesap/fotograflarim" className="text-decoration-none text-nowrap">
                                                        <i className="fas fa-images me-3 text-center" style={{width: '24px'}}></i>
                                                        Fotoğraflarım
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/hesap/puanlarim" className="text-decoration-none text-nowrap">
                                                        <i className="fas fa-chart-line me-3 text-center" style={{width: '24px'}}></i>
                                                        Puan Geçmişim
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/hesap/siparislerim" className="text-decoration-none text-nowrap">
                                                        <i className="fas fa-shopping-cart me-3 text-center" style={{width: '24px'}}></i>
                                                        Sipariş Bilgilerim
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <a href=".offcanvas" className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3" data-bs-toggle="offcanvas">
                                        <i className="fas fa-bars"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                {content}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Account;