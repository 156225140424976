import React from 'react';

const Tooltip = (props) => {
    React.useEffect(() => {
        [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(function(item) {
            return new window.bootstrap.Tooltip(item);
        });
    });

    return (
        <div data-bs-toggle="tooltip" data-bs-html="true" title={props.title}>
            {props.children}
        </div>
    );
};

export default Tooltip;