import React from 'react';

const ModalKVKK = () => {
    return (
        <div className="modal fade" id="modalKVKK">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0 rounded-3 p-4">
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    <h3 className="mb-4">BİZİM KAHRAMANLAR GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI İLE İLGİLİ BİLDİRİM</h3>
                    <p>Bildiğiniz üzere, siz bayilerimizle şirketimizin ürünlerini satmanız sebebiyle oluşan ilişkimizi geliştirmek, ürünlerimiz hakkında size bilgi vermek, sizlere boş zamanlarınızda hoş vakit geçirilebileniz için bir takım faaliyetlerde bulunma imkanı tanıyoruz. Bu kapsamda da sizlerden bazı kişisel bilgilerinizi alıyor ve bu bilgileri işliyoruz.</p>
                    <p>İşbu Gizlilik ve Kişisel Verilerin Korunması Bildirimi’nin (“Bildirim”) amacı, Jarvis ve bağlı şirketleri, iştirakleri ve hissedarlarının (“Jarvis”) kişisel verilerinizi işlerken, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) düzenlemeleri ile uyumlu olmasını ve bilgilerinizin gizliliğini sağlamaktır.</p>
                    <p>Kanunun ihlali Jarvis tarafından ciddi bir şekilde ele alınacak olup, Jarvis söz konusu ihlali disiplin prosedürleri kapsamında değerlendirebilecektir. Kanunun amaçları çerçevesinde, aşağıdaki tanımlar esas alınacaktır:</p>
                    <ul>
                        <li>a. Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi;</li>
                        <li>b. Kişisel Verilerin İşlenmesi: Kişisel Verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi Kişisel Veriler üzerinde gerçekleştirilen her türlü işlemi;</li>
                        <li>c. Özel Nitelikli Kişisel Veriler: Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili Kişisel Veriler ile biyometrik ve genetik verileri;</li>
                        <li>d. Veri Sorumlusu: Kişisel Verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan herhangi bir gerçek veya tüzel kişiyi;</li>
                        <li>e. Veri İşleyen: Jarvis tarafından verilen yetkiye dayanarak onun adına Kişisel Verileri işleyen gerçek veya tüzel kişiyi;</li>
                        <li>f. Veri Sahibi: Kişisel Verileri işlenen gerçek kişiyi;</li>
                        <li>g. Veri Kayıt Sistemi: Jarvis tarafından kullanılan Kişisel Verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini;</li>
                        <li>h. Kurul: Kişisel Verileri Koruma Kurulunu;</li>
                        <li>i. Kurum: Kişisel Verileri Koruma Kurumunu;</li>
                        <li>j. Kanun: 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayınlanmış olan 6698 sayılı Kişisel Verilerin Korunması Kanunu’nu ifade etmektedir.</li>
                    </ul>
                    <h6>1. İşbu Bildirim ile Jarvis, Veri Sahibini bilgilendirmeyi amaçlamaktadır ve Bildirimin içeriği aşağıdaki şekildedir:</h6>
                    <ul>
                        <li>a. Jarvis tarafından toplanan Kişisel Verilerin içerik ve kategorileri; kullanılış ve aktarım seçenekleri;</li>
                        <li>b. Kişisel Verilerin işleniş biçimleri;</li>
                        <li>c. Kişisel Verilerin muhafaza edilme biçimleri;</li>
                        <li>d. Veri Sahiplerinin hakları;</li>
                        <li>e. Kişisel Verilerin korunmasına ilişkin alınan önlemler;</li>
                        <li>f. Bizim Kahramanlar kanalı ile alınan Kişisel Verinin İşlenmesine Dair Esaslar</li>
                        <li>1. Jarvis’in işbu Bizim Kahramanlar sitesi ile amacı, ana sözleşmelerinde belirtilmiş olan amaçların bütünüdür.</li>
                    </ul>
                    <h6>2. Jarvis'in amacı ile ilişkili olarak, Bizim Kahramanlar üyeleri ile ilgili olarak toplanabilecek ve işlenebilecek Kişisel Veriler aşağıda sıralanır ve bu liste Jarvis’in amaçları doğrultusunda genişletilebilir:</h6>
                    <ul>
                        <li>a. Nüfus cüzdanı, sürücü belgesi, pasaport, ikametgâh, nüfus kayıt örneği, evlilik cüzdanı vb. kimlik belgeleri ve bunların örnekleri;</li>
                        <li>b. Telefon numarası, elektronik posta adresi bilgileri;</li>
                    </ul>
                    <h6>3. Jarvis, Kişisel Verileri sadece aşağıda belirtilen amaç ve dayanaklar çerçevesinde işleyeceğini taahhüt etmektedir;</h6>
                    <ul>
                        <li>a. Kişisel Verileri işleme amacı aşağıdaki gibidir: Kanun madde 5(2)(c)’de düzenlenen istisnalar hariç olmak kaydıyla,</li>
                        <li>b. Daha önceden elde edilmiş verilerin ileriki işlemlerde kullanılması;</li>
                        <li>c. Ticari uyuşmazlıkların çözümü;</li>
                        <li>d. Zaman kazanılması;</li>
                        <li>e. Veri güvenliğinin temini amacıyla verilerin yurtdışı veya yurtiçi serverlara aktarılması;</li>
                        <li>f. Verilerin yedeklenmesi;</li>
                        <li>g. Dış ve iç denetim, muhasebe, vergi danışmanlığı;</li>
                        <li>h. İç veri aktarımının yürütülmesi;</li>
                        <li>i. Bilgi Teknolojileri, tercüme, hukuki danışmanlık hizmeti;</li>
                        <li>j. Geleceğe dönük planlama;</li>
                        <li>k. İstatistik tutulması;</li>
                        <li>l. Geçmiş çalışmaların takibi;</li>
                        <li>m. İş yerinde düzen ve kontrol, yönetim, uyumun sağlanması;</li>
                        <li>l. Geçmiş çalışmaların takibi;</li>
                        <li>n. Ofis etkinliklerinden edinilen verilerin arşivlenmesi;</li>
                    </ul>
                    <h6>4. Veri Toplama Yöntemi</h6>
                    <ul>
                        <li>a. Jarvis, Kişisel Verileri aşağıdaki belirtilen yöntemler ile toplayacaktır:</li>
                        <li>1. www.jarvis.com.tr web sitesi üzerinden yapılan iletişimler de dahil olmak üzere elektronik posta;</li>
                        <li>2. Faks;</li>
                        <li>3. Telefon;</li>
                        <li>4. Posta;</li>
                        <li>5. Kurye;</li>
                        <li>6. Elden teslim.</li>
                    </ul>
                    <h6>5. İşleme ve Aktarım İzni</h6>
                    <p>a.Yurtiçinde İşleme ve Aktarım: <br />Jarvis’in ilgili kişilerin Kişisel Verilerinin yurtiçinde işlenmesi ve üçüncü gerçek ve tüzel kişilere aktarımı, ilgili kişinin açık rızası ile mümkün olup açık rıza yoksa ancak aşağıdaki şartların varlığında gerçekleşecektir:</p>
                    <ul>
                        <li>1. Kanunlarda açıkça öngörülmesi;</li>
                        <li>2. Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması;</li>
                        <li>3. Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait Kişisel Verilerin işlenmesinin gerekli olması;</li>
                        <li>4. Jarvis’in hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması;</li>
                        <li>5. İlgili kişinin kendisi tarafından alenileştirilmiş olması;</li>
                        <li>6. Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması;</li>
                        <li>7. İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Jarvis ve/ veya diğer Veri Sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>
                    </ul>
                    <p>b.Yurtdışında Kişisel Veri İşleme ve Aktarım:</p>
                    <ul>
                        <li>1. Jarvis ancak Veri Sahiplerinin açık rızası ile Kişisel Verileri yurtdışında işleyebilir ve aktarabilir.</li>
                        <li>2. Jarvis Kanun’da belirtilen şartların mevcudiyeti halinde Veri Sahibinin açık rızası olmaksızın Kişisel Verileri yurt dışına aktarabilir ve bunlara ek olarak;</li>
                        <li>1.Kişisel Verinin aktarılacağı yabancı ülkede yeterli korumanın bulunması;</li>
                        <li>2.Yeterli korumanın bulunmaması durumunda, Jarvis’in ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun izninin bulunması halinde Jarvis Kişisel Verileri yurt dışına aktarabilir.</li>
                        <li>c. Uluslararası sözleşme hükümleri saklı kalmak üzere, Türkiye’nin veya ilgili Veri Sahibinin menfaatinin ciddi bir şekilde zarar göreceği durumlarda, Jarvis ancak ilgili kamu kurum veya kuruluşunun görüşünü alarak Kurulun izniyle Kişisel Verileri yurt dışına aktarabilir.</li>
                    </ul>
                    <h6>6. Kişisel Verilerin Güvenliği</h6>
                    <p>Jarvis, Kişisel Verilerin güvenliğini aşağıdaki amaçları gerçekleştirmek için sağlayacak ve işbu amaçları sağlamak için uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri alacaktır;</p>
                    <ul>
                        <li>a. Kişisel Verilerin hukuka aykırı olarak işlenmesini önlemek;</li>
                        <li>b. Kişisel Verilerin muhafazasını sağlamak.</li>
                        <li>c. Jarvis, Kişisel Verilerin kendi adına başka bir gerçek veya tüzel kişi tarafından işlenmesi hâlinde, bu Bildirimde belirtilen tedbirlerin alınması hususunda bu diğer Veri İşleyenlerle birlikte müştereken sorumludur.</li>
                        <li>d. Jarvis, kendi kurum veya kuruluşunda, Kanun hükümlerinin uygulanmasını sağlamak amacıyla gerekli denetimleri yapmak veya yaptırmak zorundadır.</li>
                        <li>e. Jarvis ile Veri İşleyenler, öğrendikleri Kişisel Verileri Kanun hükümlerine aykırı olarak başkasına açıklayamaz ve işleme amacı dışında kullanamazlar. Bu yükümlülük, görevlerinin sona ermesinden sonra da devam eder.</li>
                        <li>f. İşlenen Kişisel Verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi hâlinde, Jarvis bu durumu en kısa sürede Veri Sahibine ve Kurula bildirir. Kurul, gerekmesi hâlinde bu durumu, kendi internet sitesinde ya da uygun göreceği başka bir yöntemle ilan edebilir.</li>
                    </ul>
                    <h6>7. Veri Sahibinin Hakları</h6>
                    <p>Herkes Jarvis’e başvurarak kendisiyle ilgili aşağıdaki haklara sahiptir.</p>
                    <ul>
                        <li>a. Kişisel Verilerinin işlenip işlenmediğini öğrenme;</li>
                        <li>b. Kişisel Verileri işlenmişse buna ilişkin bilgi talep etme;</li>
                        <li>c. Kişisel Verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme;</li>
                        <li>d. Yurt içinde veya yurt dışında Kişisel Verilerin aktarıldığı üçüncü kişileri bilme;</li>
                        <li>e. Kişisel Verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme;</li>
                        <li>f. Kanunun 7. maddesi çerçevesinde Kişisel Verilerin silinmesini veya yok edilmesini isteme;</li>
                        <li>g. Yukarıdaki maddelerde belirtilen işlemlerin, Kişisel Verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme;</li>
                        <li>h. İşlenen Kişisel Verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle Veri Sahibinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, ve</li>
                        <li>i. Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                    </ul>
                    <p>Yukarıdaki 7.maddede belirtilen hakların kullanılması için Kişiler Verilere ilişkin olarak ilgili kişilerin kimliklerinin tespitini sağlayacak bilgiler ile birlikte söz konusu taleplerin aşağıdaki iletişim yollarından yararlanarak yazılı şekilde iletilmesi gerekmektedir:</p>
                    <p>Jarvis’e bu amaçla kullanacağı info@jarvis.com.tr e-mail adreslerine elektronik posta, “Ataşehir - İstanbul” adresine posta</p>
                    <h6>8. Kişisel Verilerin Doğru ve Güncel Olarak Muhafaza Edilmesi ve Korunmasına İlişkin Önlemler</h6>
                    <p>Jarvis, Kişisel Verileri aşağıdaki yöntemler dâhilinde doğru ve güncel olarak muhafaza etmektedir:</p>
                    <ul>
                        <li>a. Günlük yedeklemeler;</li>
                        <li>b. Firewall;</li>
                        <li>c. Antivirüs programları;</li>
                        <li>d. İdari kısıtlamalar;</li>
                        <li>e. Güçlü ve komplike şifre bildirimi;</li>
                        <li>f. Erişim kontrolü sistemleri;</li>
                        <li>g. Bilginin şifrelenmesi; ve</li>
                        <li>h. Kullanıcı erişim yetkilendirmeleri.</li>
                    </ul>
                    <h6>9. Kişisel Verilerin Korunması Bildiriminde Yapılacak Değişiklikler</h6>
                    <p>Jarvis, işbu Bildirimde faaliyetlerin gerektirdiği ölçüde veya yasal açıdan gerekli olan değişiklikleri yapabilir. Değişikliğe uğramış olan Bildirim metni, www.bizimkahramanlar.com sitesinde paylaşılması ile birlikte geçerlilik kazanacaktır.</p>
                </div>
            </div>
        </div>
    );
};

export default ModalKVKK;