import React from 'react';
import { Navigate } from 'react-router-dom';
import {
    NavLink,
    useLocation,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    TitleBackground,
    Tooltip
} from '../../components';

const Product = () => {
    const navigate = useNavigate();
    const { product } = useParams();
    const location = useLocation();
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {

        //check expireSecond
        if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
            localStorage.clear();
            setState({
                ...state,
                isAuthorized: false
            });
            navigate('/login');
        }
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const productItem = state.responseProducts.filter((item) => { return item.id == product })[0];

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5" data-title-background="pt">
                    <TitleBackground className="d-lg-block d-none" />
                    <div className="container">
                        <div className="d-lg-flex d-none text-white small mb-4 opacity-50" data-title-background>
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <NavLink to="/bizim-dukkan" className="text-white text-decoration-none me-3">Bizim Dükkan</NavLink>
                            <span className="me-3">»</span>
                            <NavLink to={`/bizim-dukkan/${state.responseProducts.filter((item) => { return item.id == product })['0'].subCategoryId}`} className="text-white text-decoration-none me-3">
                                {state.responseProductCategories.map((item) => {
                                    return item.subCategoryList.map((item) => {
                                        if (item.id == state.responseProducts.filter((item) => { return item.id == product })['0'].subCategoryId) {
                                            return item.name
                                        }
                                    });
                                })}
                            </NavLink>
                        </div>
                        <div className="row gy-5">
                            <div className="col-lg-5">
                                <div className="position-relative rounded-3 overflow-hidden mb-2">
                                    <div className="bg-white border-blue-lighter">
                                        <a href={Constants.IMAGES_BASE_URL + '/' + productItem.icon} className="d-block" data-fancybox>
                                            <img src={Constants.IMAGES_BASE_URL + '/' + productItem.icon} alt="" className="w-100 img-contain" style={{width: '500px', height: '600px'}} />
                                        </a>
                                    </div>
                                    <i className="fas fa-expand h4 mb-0 position-absolute z-index-top top-0 end-0 m-sm-3 m-2 text-gray"></i>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 offset-0">
                                <h1 className="mb-5 text-white text-white-lg" data-title-background>{productItem.name}</h1>
                                <div className="pt-lg-5 pt-0">
                                    <div className="mb-5">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                                <span className="text-blue small mb-2 d-block">{productItem.stock} Stok</span>
                                                <div className="d-flex align-items-center">
                                                    <span className="h2 text-green mb-0 fw-bold">{productItem.score}</span>
                                                    <span className="text-green fw-bold ms-2">Puan</span>
                                                </div>
                                                {productItem.score > state.responseLogin.employeeFirm.score.score ? (
                                                    <span className="small text-orange mt-3 d-block"><i className="fas fa-info-circle me-2"></i>
                                                        {productItem.score - state.responseLogin.employeeFirm.score.score} Puan’a ihtiyacın var
                                                    </span>
                                                ) : null}
                                            </div>
                                            {productItem.score > state.responseLogin.employeeFirm.score.score ? (
                                                <Tooltip title="Üzgünüz, bu ürünü sipariş edebilmek için yeterli puanınız yok 😔">
                                                    <div className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-3 text-nowrap disabled">
                                                        <i className="fas fa-shopping-cart me-3 d-sm-inline-block d-none"></i>Sipariş Ver
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <NavLink to={`/sepet/${product}`} className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-3 text-nowrap">
                                                    <i className="fas fa-shopping-cart me-3 d-sm-inline-block d-none"></i>Sipariş Ver
                                                </NavLink>
                                            )}
                                        </div>
                                    </div>
                                    {productItem.description ? (
                                        <p className="mt-5 fw-light">{productItem.description}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-sm-10 mb-5">
                    <div className="container">
                        <h2 className="text-center mb-sm-8 mb-5">Diğer Ürünler</h2>
                        <div className="row g-sm-4 g-2">
                            {state.responseProducts.filter((item) => {
                                return item.subCategoryId == productItem.subCategoryId;
                            }).sort(() => {
                                return 0.5 - Math.random()
                            }).slice(0, 4).map((item, i) => (
                                <div className="col-xl-3 col-6" key={i}>
                                    <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                                        <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 p-3" style={{width: '300px', height: '200px'}} />
                                        <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                                            <h5 className="fw-normal lh-base mb-2">{item.name}</h5>
                                            <span className="d-block small text-blue mb-4">{item.score} Puan</span>
                                            <NavLink to={`/urun/${item.id}`} className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto">
                                                İncele<i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none"></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Product;