import React from 'react';
import {
    NavLink,
    Navigate,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import { Footer, Header } from '../../components';

const Cart = () => {
    const navigate = useNavigate();
    const { product } = useParams();
    const [state, setState] = React.useContext(Context);
    const [pageState, setPageState] = React.useState({});

    React.useEffect(() => {
        if (state.isAuthorized) {
            
            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }

            fetch(Constants.API_BASE_URL + '/address/' + state.responseLogin.employeeFirm.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                setPageState({
                    ...pageState,
                    responseAddresses: response?.data
                });
            });
        }
    }, []);
    
    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const productItem = state.responseProducts.filter((item) => { return item.id == product })[0];

    const updateUser = async (newState) => {
        let tempState = {};
    
        //responseLogin.employeeFirm
        await fetch(Constants.API_BASE_URL + '/newScore/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            tempState = {
                ...tempState,
                responseLogin: {
                    ...state.responseLogin,
                    employeeFirm: response['data']
                }
            }
        });
    
        //responseScoreTransactions
        await fetch(Constants.API_BASE_URL + '/scoreTransactions/' + tempState.responseLogin.userId + '?page=0&size=9999', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tempState.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            if (response?.data?.scoreTransactionDTOS) {
                tempState = {
                    ...tempState,
                    responseScoreTransactions: response['data']['scoreTransactionDTOS']
                }
            }
        });

        setState({
            ...newState ? newState : state,
            ...tempState
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch(Constants.API_BASE_URL + '/orderList/saveOrder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify({
                employeeFirmId: state.responseLogin.employeeFirm.id,
                productId: parseInt(product),
                score: state.responseLogin.employeeFirm.score.score,
                selectedAddress: Object.fromEntries(new FormData(event.target)).address
            })
        })
        .then((response) => {
            if (response.status == 200) {
                updateUser();
                setPageState({
                    ...pageState,
                    orderCompleted: true
                });
            }
        });
    };

    return (
        <React.Fragment>
            <Header />

            <main>
                <section className="bg-blue py-5 mb-5">
                    <div className="container">
                        <h1 className="text-white mb-0 text-center">Sipariş Oluştur</h1>
                    </div>
                </section>

                <section className="my-sm-10 my-5">
                    {pageState.orderCompleted ? (
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-10">
                                    <div className="bg-gray-lightest rounded-3 p-5 text-center mb-5">
                                        <h3 className="mb-5 text-green">🎉 Siparişiniz Alındı!</h3>
                                        <p className="lead mb-5">Hediyeniz <strong>15 iş günü</strong> içerisinde kargo aracılığıyla size ulaşacaktır. İyi Günlerde Kullanın!</p>
                                        <NavLink to="/hesap/siparislerim" className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3">
                                            Siparişlerim
                                        </NavLink>
                                    </div>
                                    <p className="small fw-light opacity-75 text-center">
                                        Siparişiniz ile ilgili sorularınız için Sıkça Sorulan Sorular
                                        bölümünden yardım alabilir, Benim Bayim Çağrı Merkezi'ni 0850
                                        532 5 584 nolu telefondan Pazartesi ila Cuma günleri
                                        09:00-18:00 saatleri arasında arayabilir veya Bize Ulaşın'dan
                                        bize erişebilirsiniz.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={(event) => handleSubmit(event)}>
                            <div className="container">
                                <div className="row justify-content-center gx-5 gy-5">
                                    <div className="col-xl-7 col-lg-8">
                                        <h5 className="mb-4 text-blue">Ürün</h5>
                                        <div className="d-flex align-items-center border-bottom pb-5 mb-5">
                                            <img src={Constants.IMAGES_BASE_URL + '/' + productItem.icon} alt="" className="img-contain rounded-3 shadow-lg" style={{width: '200px', height: '150px'}} />
                                            <div className="ms-4">
                                                <h6>{productItem.name}</h6>
                                                {productItem.description ? (
                                                    <p className="mt-3 mb-0 fw-light opacity-75">{productItem.description}</p>
                                                ) : null}
                                            </div>
                                        </div>

                                        <h5 className="mb-4 d-flex justify-content-between align-self-center">
                                            <span className="text-blue">Teslimat Adresi</span>
                                            <NavLink to="/hesap/adreslerim" className="fs-6 text-green">
                                                <small>Yeni Adres Ekle</small>
                                            </NavLink>
                                        </h5>
                                        <div className="border-bottom pb-5 mb-5">
                                            <div className="form-floating">
                                                <select className="form-select" name="address" required>
                                                    <option value="">Seçiniz</option>
                                                    {pageState?.responseAddresses?.map((item, i) => (
                                                        <option value={item.name} key={i}>{item.name}</option>
                                                    ))}
                                                </select>
                                                <label>Adres</label>
                                            </div>
                                        </div>

                                        <div className="form-check my-4">
                                            <input className="form-check-input" type="checkbox" value="" id="confirm" required />
                                            <label className="form-check-label small" htmlFor="confirm">Aşağıdaki şart ve koşulları okudum, onaylıyorum.</label>
                                        </div>
                                        <div className="fw-light overflow-scroll" style={{fontSize: '14px', height: '150px'}}>
                                            <p>Gerçek ürün ile resimdeki ürün arasında renk, boy, şekil, baskı
                                            ve benzeri fiziksel özelliklerde farklılık olabilir. "Siparişi
                                            Onayla" butonuna tıklamadan önce tüm detayları kontrol
                                            etmelisiniz çünkü onaylamış olduğunuz siparişler değiştirilemez,
                                            iptal edilemez, puan iadesi yapılamaz. Benim Bayim’den
                                            siparişini vermiş olduğunuz ürünler, sipariş verildikten sonra
                                            on beş (15) iş günü (resmi tatiller ve bayram tatilleri, ile
                                            hafta sonu günleri iş günlerine dahil değildir) içinde sistemde
                                            kayıtlı bulunan ve sipariş adresi olarak seçtiğiniz adresinize
                                            teslim edilecektir. Siparişiniz için kargo ücreti ödemezsiniz.
                                            Ürün teslim alınırken, ürünün doğru, sağlam ve eksiksiz olduğunu
                                            kontrol etmek ve öyle teslim almak, teslim alan kişinin
                                            sorumluluğundadır. Kontrol sırasında, hasarlı ürün tespit
                                            edilirse siparişinizi teslim eden kargo firması ile birlikte
                                            tutanak tutmanız gerekmektedir. Tutanak tutulduktan sonra 0850
                                            532 5 584 (JTI) numaralı Çağrı Merkezi’mizi arayarak iade
                                            sürecini başlatabilirsiniz. Tutanak tutulmaması durumunda
                                            tarafımıza yapılacak hasarlı ürün iadeleri ve itirazlar kabul
                                            edilmeyecektir. JTI gerekli gördüğü durumlarda Benim Bayim
                                            Dükkanı’nda yer alan ürünlerin puanlarını değiştirme veya
                                            dilediği ürünü Benim Bayim Dükkanı’ndan kaldırma hakkına
                                            sahiptir. Sitede yer alan ürünler tedarikçi stokları ile
                                            sınırlıdır. Stoğu tükenmiş ürünler için Kullanıcı hiçbir hak
                                            iddiasında bulunamaz. JTI, Benim Bayim Dükkanı’nda yer alan
                                            ürünlerin tedariğinde sorun çıkması durumunda, ürünlerin
                                            muadilini veya bir üst modelini gönderebilir. Kullanıcı,
                                            kendisine gönderilen ürünün Benim Bayim Dükkanı’nda sergilenen
                                            ürün olmadığına dair JTI’dan hak talep edemez. 25 Mayıs - 13
                                            Aralık 2020 tarihleri arasında sürecek olan Benim Bayim’in 2020
                                            dönemi boyunca Benim Bayim Dükkanı’ndaki hediyelerden en fazla 2
                                            adet sipariş verebilirsiniz. Bu dönemde kazandığınız
                                            puanlarınızı 13 Aralık 2020 günü saat 23:59'a kadar
                                            harcayabilirsiniz. 13 Aralık 2020 günü saat 23:59 itibariyle
                                            kalan puanlar silinecektir. ‘’Siparişi Onayla’’ butonuna
                                            tıkladığınızda burada yazılı olan şartları kabul etmiş
                                            sayılacaksınız.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4">
                                        <div className="bg-gray-lightest rounded-3 p-4">
                                            <h5 className="mb-4">Sipariş Özeti</h5>
                                            <div className="d-flex justify-content-between border-bottom py-3 small">
                                                <div>Mevcut Puanınız</div>
                                                <div>{state.responseLogin.employeeFirm.score.score}</div>
                                            </div>
                                            <div className="d-flex justify-content-between border-bottom py-3 small">
                                                <div>Sipariş Tutarı (Puan)</div>
                                                <div>{productItem.score}</div>
                                            </div>
                                            <div className="d-flex justify-content-between py-3 small text-blue">
                                                <div>Sipariş Sonrası Puan</div>
                                                <div>{state.responseLogin.employeeFirm.score.score - productItem.score}</div>
                                            </div>
                                            <button type="submit" className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3 mt-4 w-100">Siparişi Tamamla</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Cart;