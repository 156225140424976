import React from 'react';
import { NavLink } from 'react-router-dom';

const Pagination = (props) => {
    const {
        pageActive,
        pagePath,
        pagePer,
        pageTotal
    } = props;

    return (
        <ul className="pagination pagination-lg justify-content-center mb-0">
            <li className={`page-item${pageActive > 1 ? '' : ' disabled'}`}>
                <NavLink to={pagePath + '/' + (parseInt(pageActive) - 1)} className="page-link">
                    <span>&laquo;</span>
                </NavLink>
            </li>
            {[...Array(Math.ceil(pageTotal / pagePer))].map((item, i) => {
                return (
                    (i + 1 < pageActive) && (i + 1 + 2 >= pageActive) ||
                    i + 1 == pageActive ||
                    (i + 1 > pageActive) && (i + 1 - 2 <= pageActive)
                ) ? (
                    <li className={`page-item d-lg-inline-block d-none${(i + 1) == pageActive ? ' active' : ''}`} key={i}>
                        <NavLink to={pagePath + '/' + (i + 1)} className={`page-link${(i + 1) == pageActive ? ' bg-blue' : ''}`}>
                            {i + 1}
                        </NavLink>
                    </li>
                ) : null
            })}
            <li className={`page-item${pageActive < Math.ceil(pageTotal / pagePer) ? '' : ' disabled'}`}>
                <NavLink to={pagePath + '/' + (parseInt(pageActive) + 1)} className="page-link">
                    <span>&raquo;</span>
                </NavLink>
            </li>
        </ul>
    );
};

export default Pagination;