import React from 'react';

const ModalWelcome = () => {
    return (
        <div className="modal fade" id="modalWelcome">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div className="modal-content border-0 rounded-3">
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    <a href="#"><img src="https://via.placeholder.com/800x500" alt="" className="w-100" /></a>
                </div>
            </div>
        </div>
    );
};

export default ModalWelcome;