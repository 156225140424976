import React from 'react';
import $ from 'jquery';


const Toast = () => {
    const [pageState, setPageState] = React.useState({
        color: 'bg-green'
    });

    React.useEffect(() => {
        window.showToast = (props, isError) => {
            if (isError) {
                setPageState({
                    ...pageState,
                    color: 'bg-red'
                });
            } ;
            $('.toast').find('.toast-body').html(props);
            var toastElList = [].slice.call(document.querySelectorAll('.toast'))
            var toastList = toastElList.map(function(toastEl) {
                return new window.bootstrap.Toast(toastEl)
            });
            toastList.forEach(toast => toast.show());
        };
    });

    return (
        <div className="position-fixed top-0 start-50 translate-middle-x p-3" style={{zIndex: '2000'}}>
            <div className={`toast align-items-center text-white ${pageState.color} border-0`}>
                <div className="d-flex">
                    <div className="toast-body p-3"></div>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"></button>
                </div>
            </div>
        </div>
    );
};

export default Toast;