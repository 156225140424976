import React from 'react';
import {
    NavLink,
    Navigate,
    useLocation,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import { Footer, Header } from '../../components';

const Comments = () => {
    const navigate = useNavigate();
    const { commentCategory } = useParams();
    const location = useLocation();
    const [state, setState] = React.useContext(Context);
    const [pageState, setPageState] = React.useState({
        isCommenting: false,
        newCommentLengthLeft: 500
    });

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }
        }
    }, []);

    React.useEffect(() => {
        if (state.isAuthorized) {
            window.scrollTo(0, 0);

            if (commentCategory) {
                fetch(Constants.API_BASE_URL + '/forum/past/findAll/selected/' + state.responseLogin.employeeFirm.firm.id + '/' + commentCategory + '?page=0&size=9999', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + state.responseLogin.token
                    }
                })
                .then((response) => { return response.json(); })
                .then((response) => {
                    if (response['data'] && response['data']['forumMessageDTOS']) {
                        let responseCommentsOld = state.responseCommentsOld ? state.responseCommentsOld : {};
                        responseCommentsOld[commentCategory] = response['data']['forumMessageDTOS'];
                        setState({
                            ...state,
                            commentsCount: 10,
                            responseCommentsOld: responseCommentsOld
                        });
                    }
                })
            }
        }
    }, [location]);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const handleLoadMore = (event) => {
        event.preventDefault();
        setState({
            ...state,
            commentsCount: (state.commentsCount ? state.commentsCount : 10) + 10
        });
    };

    const handleNewCommentChange = (event) => {
        setPageState({
            ...pageState,
            newCommentLengthLeft: 500 - (event.target.value).length
        });
    };

    const handleCommentSubmit = (event, subjectId) => {
        event.preventDefault();

        fetch(Constants.API_BASE_URL + '/forum/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify({
                message: Object.fromEntries(new FormData(event.target)).message,
                subjectId: subjectId,
                senderId: state.responseLogin.employeeFirm.firm.id
            })
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            if (response['data'] && response['data']['success']) {
                document.getElementsByName('message')[0].value = '';
                setPageState({
                    ...pageState,
                    isCommenting: false,
                    newCommentLengthLeft: 500
                });
                window.showToast('Yorumunuz onaylandıktan sonra görüntülenecektir.');
            }
        });
    }

    const handleCommentLikeClick = (event, forumMessageId, senderId, subjectId, forumLikeId) => {
        event.preventDefault();

        let body;

        if (event.currentTarget.classList.contains('text-red')) {
            //dislike
            body = JSON.stringify({
                forumMessageId: null,
                senderId: senderId,
                subjectId: subjectId,
                forumLikeId: null
            });
            event.currentTarget.classList.add('fw-light');
            event.currentTarget.classList.remove('text-red');
            event.currentTarget.nextElementSibling.textContent = parseInt(event.currentTarget.nextElementSibling.textContent) - 1;
        } else {
            //like
            body = JSON.stringify({
                forumMessageId: null,
                senderId: senderId,
                subjectId: subjectId,
                forumLikeId: null
            });
            event.currentTarget.classList.add('text-red');
            event.currentTarget.classList.remove('fw-light');
            event.currentTarget.nextElementSibling.textContent = parseInt(event.currentTarget.nextElementSibling.textContent) + 1
        }

        fetch(Constants.API_BASE_URL + '/forumLike/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: body
        })
        .then((response) => { return response.json(); })
        .then((response) => { return response['data']; })
    };

    const handleCommentReplyClick = (event, senderName) => {
        event.preventDefault();
        setPageState({
            ...pageState,
            isCommenting: true,
            newCommentLengthLeft: 500 - ('@' + senderName + ' ').length
        });
        setTimeout(() => {
            window.scrollTo(0, 0);
            document.getElementsByName('message')[0].value = '@' + senderName + ' ';
            document.getElementsByName('message')[0].focus();
        }, 100);
    };

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="bg-blue pt-5 pb-lg-8 pb-5 mb-sm-5 mb-3">
                    <div className="container"> 
                        <div className="d-lg-flex d-none text-white small mb-5 opacity-50">
                        <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <span className="text-white text-decoration-none me-3">Bizim Dünya</span>
                            <span className="me-3">»</span>
                            <span>Bizim Meydan</span>
                        </div>
                        {!commentCategory ? (
                            <React.Fragment>
                                <div className="row justify-content-center mb-sm-5 mb-4">
                                    <div className="col-lg-6 col-md-8 text-center">
                                        <h6 className="mb-4 text-white opacity-50">Haftanın Konusu</h6>
                                        <h4 className="mb-0 lh-base text-white">{state.responseComments.subject}</h4>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-xl-8 col-lg-10">
                                        <div className="d-flex align-items-center">
                                            {state.responseLogin.employeeFirm.profilePhoto ? (
                                                <img src={Constants.IMAGES_BASE_URL + '/' + state.responseLogin.employeeFirm.profilePhoto} alt="" className="rounded-circle shadow-sm me-3 d-sm-block d-none img-cover" style={{width: '60px', height: '60px'}} />
                                            ) : (
                                                <div className="bg-white shadow-sm rounded-circle justify-content-center align-items-center fw-bold me-3 d-sm-flex d-none" style={{minWidth: '60px', minHeight: '60px', width: '60px', height: '60px'}}>
                                                    {state.responseLogin.employeeFirm.employee.name.slice(0, 1) + state.responseLogin.employeeFirm.employee.surname.slice(0, 1)}
                                                </div>
                                            )}
                                            <form className="w-100" onSubmit={(event) => handleCommentSubmit(event, state.responseComments.id)}>
                                                <div className="commentInput bg-white shadow-lg rounded-3 py-4 px-sm-5 px-3 flex-grow-1 d-flex align-items-center justify-content-between">
                                                    {pageState.isCommenting ? (
                                                        <React.Fragment>
                                                            <div className="w-100 text-end">
                                                                <textarea className="form-control text-gray border-0 p-0" rows="4" placeholder="Mesajınız" name="message" maxLength="500" onChange={(event) => handleNewCommentChange(event)}></textarea>
                                                                <div className="d-flex justify-content-between align-items-center mt-4">
                                                                    <span className={`small${pageState.newCommentLengthLeft <= 100 ? ' text-red' : ''}`}>{pageState.newCommentLengthLeft} harakter kaldı</span>
                                                                    <button type="submit" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-4" disabled={pageState.newCommentLengthLeft < 500 ? false : true }>Gönder</button>
                                                                </div>
                                                            </div>
                                                            <div className="d-none">
                                                            {setTimeout(() => {
                                                                document.getElementsByName('message')[0].focus()
                                                            }, 100)}
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <h1 className="text-gray-light mb-0" onClick={(event) => {
                                                                setPageState({
                                                                    ...pageState,
                                                                    isCommenting: true
                                                                });
                                                            }}>Düşünceni yaz…</h1>
                                                            <div className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-4 disabled">Gönder</div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                        <p className="text-center mb-0 mt-5 small text-white opacity-50"><i className="fas fa-comment me-2"></i>{state.responseComments.countComment} kişi bu konu hakkında konuşuyor.</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="row justify-content-center mb-5">
                                <div className="col-lg-6 col-md-8 text-center">
                                    <h6 className="mb-4 text-white opacity-50">Konu Başlığı</h6>
                                    <h2 className="mb-0 lh-base text-white">
                                        {state.responseCommentSubjects.find((item, i) => {
                                            return item.id == commentCategory
                                        }).subject}
                                    </h2>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                <section className="mb-sm-10 mb-5 mt-lg-0 mt-sm-5 mt-3">
                    <div className="container-lg">
                        <div className="row gy-0">
                            <div className="col-xl-3 col-lg-4 order-1">
                                <div className="ps-lg-5 ps-0">
                                    <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                                        <div className="offcanvas-header">
                                            <h5 className="offcanvas-title">Son Konular</h5>
                                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div className="offcanvas-body">
                                            <h4 className="mb-4 d-lg-block d-none">Son Konular</h4>
                                            <NavLink to="/bizim-meydan" className={`d-flex align-items-center text-reset text-decoration-none border-bottom pb-3 mb-3 ${!commentCategory ? 'text-active' : ''}`}>
                                                <span className="bg-orange text-white rounded-circle d-flex align-items-center justify-content-center flex-shrink-0 small" style={{width: '30px', height: '30px'}}>{state.responseComments.countComment}</span>
                                                <h6 className="mb-0 small fw-normal ms-3">{state.responseComments.subject}</h6>
                                            </NavLink>
                                            {state.responseCommentSubjects ? (
                                                state.responseCommentSubjects.map((item, i) => (
                                                    <NavLink to={'/bizim-meydan/' + item.id } className="d-flex align-items-center text-reset text-active text-decoration-none border-bottom pb-3 mb-3" key={i}>
                                                        <span className="bg-orange text-white rounded-circle d-flex align-items-center justify-content-center flex-shrink-0 small" style={{width: '30px', height: '30px'}}>{item.countComment}</span>
                                                        <h6 className="mb-0 small fw-normal ms-3">{item.subject}</h6>
                                                    </NavLink>
                                                ))
                                            ) : null}
                                        </div>
                                    </div>
                                    <a href=".offcanvas" className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3" data-bs-toggle="offcanvas">
                                        <i className="fas fa-list-alt"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8 order-0">
                                <div className="mt-lg-n8 mt-0 mb-n3">
                                    {!commentCategory ? (
                                        state.responseComments && state.responseComments.forumMessageList ? (
                                            <React.Fragment>
                                                {state.responseComments.forumMessageList.slice(0, state.commentsCount ? state.commentsCount : 10).map((item, i) => (
                                                    <div className="shadow-lg bg-white rounded-3 mb-3" key={i}>
                                                        <div className="row g-0">
                                                            <div className="col-3 p-xl-5 p-4 border-end text-center d-xl-block d-none">
                                                                <div className="d-flex align-items-center flex-column">
                                                                    <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold mb-4" style={{width: '80px', height: '80px'}}>
                                                                        <i className="fas fa-user h3 mb-0"></i>
                                                                    </div>
                                                                    <h6 className="small mb-0">{item.senderName ? item.senderName : 'İsimsiz'}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-9 p-xl-5 p-4 position-relative d-flex flex-column justify-content-center">
                                                                <span className="position-absolute top-0 end-0 small m-3">{item.dateBegin.split('.')[0]}</span>
                                                                <div className="row align-items-center">
                                                                    <div className="col lead fw-normal text-gray-darkest">
                                                                        <div className="d-xl-none d-flex align-items-center mb-3">
                                                                            <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold flex-shrink-0 me-3" style={{width: '40px', height: '40px'}}>
                                                                                <i className="fas fa-user h6 mb-0"></i>
                                                                            </div>
                                                                            <h6 className="mb-0">{item.senderName ? item.senderName : 'İsimsiz'}</h6>
                                                                        </div>
                                                                        <p className="mb-0">“{item.message}”</p>
                                                                    </div>
                                                                    <div className="col-xl-auto col-12">
                                                                        <div className="d-flex align-items-center justify-content-end mt-xl-0 mt-4">
                                                                            <a href="#" className="d-flex align-items-center text-reset text-decoration-none me-4">
                                                                                <i className={`fas fa-heart me-2 h4 mb-0 ${item.forumMessageLikeStatus ? 'text-red' : 'fw-light'}`} onClick={(event) => handleCommentLikeClick(event, item.id, state.responseLogin.employeeFirm.id, item.subjectId, item.id)}></i>
                                                                                <span>{item.countLike}</span>
                                                                            </a>
                                                                            <a href="#" className="d-flex align-items-center text-reset text-decoration-none" onClick={(event) => handleCommentReplyClick(event, item.senderName ? item.senderName : 'İsimsiz')}>
                                                                                <i className="fas fa-comment fw-light h4 mb-0"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                {state.responseComments.forumMessageList.length - (state.commentsCount ? state.commentsCount : 10) > 0 ? (
                                                    <div className="text-center mt-sm-6 mt-5">
                                                        <button type="button" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3" onClick={(event) => handleLoadMore(event)} >Daha Fazla Göster</button>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        ) : (
                                            <div className="text-center p-8">
                                                <h1 className="mb-4 opacity-75">Mesaj bulunamadı.</h1>
                                                <h3 className="opacity-50">Bu başlığa henüz hiç mesaj gönderilmemiş.</h3>
                                            </div>
                                        )
                                    ) : (
                                        state.responseCommentsOld && state.responseCommentsOld[commentCategory] ? (
                                            <React.Fragment>
                                                {state.responseCommentsOld[commentCategory].slice(0, state.commentsCount).map((item, i) => (
                                                    <div className="shadow-lg bg-white rounded-3 mb-3" key={i}>
                                                        <div className="row g-0">
                                                            <div className="col-3 p-xl-5 p-4 border-end text-center d-xl-block d-none">
                                                                <div className="d-flex align-items-center flex-column">
                                                                    <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold mb-4" style={{width: '80px', height: '80px'}}>
                                                                        <i className="fas fa-user h3 mb-0"></i>
                                                                    </div>
                                                                    <h6 className="small mb-0">{item.senderName ? item.senderName : 'İsimsiz'}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-9 p-xl-5 p-4 position-relative d-flex flex-column justify-content-center">
                                                                <span className="position-absolute top-0 end-0 small m-3">{item.dateBegin.split('.')[0]}</span>
                                                                <div className="row align-items-center">
                                                                    <div className="col lead fw-normal text-gray-darkest">
                                                                        <div className="d-xl-none d-flex align-items-center mb-3">
                                                                            <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold flex-shrink-0 me-3" style={{width: '40px', height: '40px'}}>
                                                                                <i className="fas fa-user h6 mb-0"></i>
                                                                            </div>
                                                                            <h6 className="mb-0">{item.senderName ? item.senderName : 'İsimsiz'}</h6>
                                                                        </div>
                                                                        <p className="mb-0">“{item.message}”</p>
                                                                    </div>
                                                                    <div className="col-xl-auto col-12">
                                                                        <div className="d-flex align-items-center justify-content-end mt-xl-0 mt-4">
                                                                            <a href="#" className="d-flex align-items-center text-reset text-decoration-none">
                                                                                <i className={`fas fa-heart me-2 h4 mb-0 ${item.forumMessageLikeStatus ? 'text-red' : 'fw-light'}`} onClick={(event) => handleCommentLikeClick(event, item.id, state.responseLogin.employeeFirm.firm.id, item.subjectId, item.id)}></i>
                                                                                <span>{item.countLike}</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                {state.responseCommentsOld[commentCategory].length - state.commentsCount > 0 ? (
                                                    <div className="text-center mt-sm-6 mt-5">
                                                        <button type="button" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3" onClick={(event) => handleLoadMore(event)}>Daha Fazla Göster</button>
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        ) : (
                                            <div className="text-center p-8">
                                                <h1 className="mb-4 opacity-75">Mesaj bulunamadı.</h1>
                                                <h3 className="opacity-50">Bu başlığa henüz hiç mesaj gönderilmemiş.</h3>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Comments;