import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Context } from '../../stores';

const NotFound = () => {
    const [state, setState] = React.useContext(Context);
    const navigate = useNavigate();
    
    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }
        }
    }, []);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    return (
        <h1>NotFound</h1>
    );
};

export default NotFound;