import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Constants, Context } from '../../stores';
import { LoginFooter, Toast } from '../../components';

const Reset = () => {
    const navigate = useNavigate();
    const [state, setState] = React.useContext(Context);

    const handleSubmit = (event) => {
        event.preventDefault();

        setState({
            ...state,
            isLoading: true
        });

        fetch(Constants.API_BASE_URL + '/public/forgotPassword', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: Object.fromEntries(new FormData(event.target)).username
        })
        .then((response) => {
            if (response.status == 200) {
                setState({
                    ...state,
                    isLoading: false
                });
                window.showToast('Yeni şifreniz telefon numaranıza gönderilmiştir');
                setTimeout(() => {
                    navigate('/');
                }, 2000);
                return false;
            } else {
                return response.json();
            }
        })
        .then(response => {
            if (response && response['errors']) {
                setState({
                    ...state,
                    isLoading: false
                });
                window.showToast(response['errors'][0]['message']);
            }
        })
    };

    return (
        <React.Fragment>
            <section className="bg-gray-lightest min-vh-100">
                <form onSubmit={(event) => handleSubmit(event)}>
                    <div className="container">
                        <div className="row justify-content-center py-md-8 py-5">
                            <div className="col-xl-4 col-lg-6 col-md-8 col-10">
                                <div className="text-center mb-6">
                                    <img src="../assets/img/logo.png" alt="" />
                                </div>
                                <h5 className="lh-base text-center fw-normal mb-3">Lütfen Kullanıcı Adınızı giriniz.</h5>
                                <p className="mb-5 text-center fw-light">Yeni şifreniz girmiş olduğunuz kullanıcı adına kayıtlı telefon numaranıza gönderilecektir.</p>

                                <div className="form-floating shadow-lg mb-4 rounded-3">
                                    <input type="text" name="username" className="form-control border-0 rounded-3 px-4" id="inputUsername" placeholder="Kullanıcı Adı" defaultValue="" required />
                                    <label htmlFor="inputUsername" className="px-4">Kullanıcı Adı</label>
                                </div>
                                <button type="submit" className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4" disabled={state.isLoading}>
                                    {state.isLoading ? (<span className="spinner-border spinner-border-sm me-3"></span>) : null}
                                    Gönder<i className="fas fa-arrow-right ms-3"></i>
                                </button>
                                <div className="text-center text-gray small">
                                    <NavLink to="/login">Giriş Yap</NavLink> veya <NavLink to="/register">Üye Ol</NavLink>
                                </div>

                                <LoginFooter />
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <Toast />
        </React.Fragment>
    );
};

export default Reset;