import React from 'react';
import {
    NavLink,
    Navigate,
    useLocation,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import { Footer, Header } from '../../components';

const Post = () => {
    const navigate = useNavigate();
    const { post } = useParams();
    const location = useLocation();
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {
        
        //check expireSecond
        if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
            localStorage.clear();
            setState({
                ...state,
                isAuthorized: false
            });
            navigate('/login');
        }
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const postItem = state.responsePostCategories.map((category) => {
        if (category.items.find((item) => { return item.id == post; })) {
            return category.items.filter((item) => {
                return item.id == post;
            });
        }
    }).filter((item) => {
        return item !== undefined
    })[0][0];

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="bg-blue pt-5 pb-5">
                    <div className="container"> 
                        <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <span className="me-3">Bizim Dünya</span>
                            <span className="me-3">»</span>
                            <NavLink to="/bizim-dergi" className="text-white text-decoration-none me-3">Bizim Dergi</NavLink>
                            <span className="me-3">»</span>
                            <NavLink to={`/bizim-dergi/${postItem.topicSubCategoryId}`} className="text-white text-decoration-none">{state.responsePostCategories.filter((item) => { return item.id == postItem.topicSubCategoryId; })[0].name}</NavLink>
                        </div>
                        <div className="row justify-content-center mb-5" data-title-background>
                            <div className="col-xl-8 col-lg-10 text-center">
                                <h1 className="lh-base mb-4 text-white">{postItem.name}</h1>
                                <div className="opacity-50 small text-white">
                                    <span>{postItem.dateCreated}</span>
                                    <span className="ms-3">#{state.responsePostCategories.filter((item) => { return item.id == postItem.topicSubCategoryId; })[0].name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="position-relative mb-sm-10 mb-5 mt-n5">
                    <div className="container">
                        <div className="row justify-content-center mb-sm-8 mb-5">
                            <div className="col-xl-10">
                                <div className="overflow-hidden rounded-3">
                                    <img src={Constants.IMAGES_BASE_URL + '/' + postItem.topIcon} alt="" className="img-cover w-100" />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10 content">
                                <p dangerouslySetInnerHTML={{__html: postItem.description}}></p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-sm-10 mb-5">
                    <div className="container">
                        <h2 className="text-center mb-sm-8 mb-5">İlginizi Çekebilir</h2>
                        <div className="row g-sm-4 g-2">
                            {state.responsePostCategories.find((item) => {
                                return item.id == postItem.topicSubCategoryId
                            }).items.sort(() => {
                                return 0.5 - Math.random()
                            }).slice(0, 4).map((item, i) => (
                                <div className="col-xl-3 col-6" key={item.id}>
                                    <NavLink to={`/makale/${item.id}`} className="d-block bg-white text-decoration-none shadow rounded-3 overflow-hidden h-100">
                                        <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-cover w-100" style={{width: '300px', height: '200px'}} />
                                        <div className="p-sm-4 p-3">
                                            <h5 className="fw-normal lh-base mb-3">{item.name}</h5>
                                            <div className="text-blue small">
                                                <span>#{state.responsePostCategories.filter((item) => { return item.id == postItem.topicSubCategoryId; })[0].name}</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Post;