import React from 'react';
import {
    NavLink,
    Navigate,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    Toast
} from '../../components';

const Home = () => {
    const [state, setState] = React.useContext(Context);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }

            if (state.responseLogin.employeeFirm.score.scoreType && !state.scoreMessageShown) {
                window.showToast('Tebrikler! Giriş yaptığınız için ' + state.responseLogin.employeeFirm.score.diffScore +' puan kazandınız.');
                setState({
                    ...state,
                    scoreMessageShown: true
                });
            }

            //responseMessageCount
            fetch(Constants.API_BASE_URL + '/contactMessageNumber/' +  state.responseLogin.employeeFirm.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                setState({
                    ...state,
                    responseMessageCount: response?.data ? response.data : null
                });
            });

            const sliderHero = new window.Swiper('.sliderHero', {
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.sliderHero .swiper-pagination',
                    clickable: true
                }
            });

            const sliderBrands = new window.Swiper('.sliderBrands .swiper', {
                breakpoints: {
                    0: {
                        spaceBetween: 24,
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        grid: {
                            rows: 2,
                            fill: 'row'
                        },
                    },
                    992: {
                        spaceBetween: 24,
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        grid: {
                            rows: 2,
                            fill: 'row'
                        },
                    }
                },
                pagination: {
                    el: '.sliderBrands .swiper .swiper-pagination',
                    clickable: true
                }
            });

            const sliderGames = new window.Swiper('.sliderGames', {
                breakpoints: {
                    0: {
                        slidesPerView: 1.5
                    },
                    576: {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 3
                    },
                    1200: {
                        slidesPerView: 4
                    }
                },
                spaceBetween: -8,
                pagination: {
                    el: '.sliderGames .swiper-pagination',
                    clickable: true
                },
            });

            const masonry = new window.Masonry('[data-masonry]');
        }
    }, []);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const updateUser = async (newState) => {
        let tempState = {};
    
        //responseLogin.employeeFirm
        await fetch(Constants.API_BASE_URL + '/newScore/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            tempState = {
                ...tempState,
                responseLogin: {
                    ...state.responseLogin,
                    employeeFirm: response['data']
                }
            }
        });
    
        //responseScoreTransactions
        await fetch(Constants.API_BASE_URL + '/scoreTransactions/' + tempState.responseLogin.userId + '?page=0&size=9999', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tempState.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            if (response?.data?.scoreTransactionDTOS) {
                tempState = {
                    ...tempState,
                    responseScoreTransactions: response['data']['scoreTransactionDTOS']
                }
            }
        });

        setState({
            ...newState ? newState : state,
            ...tempState
        });
    };

    const handleSurveySubmit = (event) => {
        event.preventDefault();

        fetch(Constants.API_BASE_URL + '/survey/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            },
            body: JSON.stringify({
                survey: state.responseSurvey[0].id,
                employeeFirmId: state.responseLogin.userId,
                surveyChoice: [parseInt(Object.fromEntries(new FormData(event.target)).surveyChoice)]
            })
        })
        .then(async () => {
            updateUser({
                ...state,
                responseSurvey: [
                    {
                        ...state.responseSurvey[0],
                        answeredSurvey: true
                    }
                ]
            });
        });
    };

    return (
        <React.Fragment>
            <Header />
            <main>
                {state.responseAnnouncementListSlider ? (
                    <section className="sliderHero swiper mb-5">
                        <div className="swiper-wrapper align-items-stretch">
                            {state.responseAnnouncementListSlider.map((item, i) => (
                                <React.Fragment key={i}>
                                    {item.layout == 4 ? (
                                        <div className="swiper-slide h-auto">
                                            <a href="#">
                                                <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-cover w-100 h-100 d-lg-block d-none" style={{maxWidth: '1920px', maxHeight: '650px'}} />
                                                <img src={Constants.IMAGES_BASE_URL + '/' + item.iconPhone} alt="" className="img-cover w-100 h-100 d-lg-none d-block" />
                                            </a>
                                        </div>
                                    ) : (
                                        <div className="swiper-slide d-flex h-auto align-items-center" style={item.backgroundColor ? {backgroundColor: '#' + item.backgroundColor} : null}>
                                            <div className="container">
                                                <div className="row py-lg-5 pt-3 pb-5 align-items-center text-lg-start text-center">
                                                    <div className={`col-lg-6${item.layout == 2 ? ' order-lg-1 order-0' : ''}`}>
                                                        <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 mb-5" style={{width: '700px', height: '500px'}} />
                                                    </div>
                                                    <div className={`col-lg-6${item.layout == 2 ? ' order-lg-0 order-1' : ''}`}>
                                                        <div className="ps-xl-8 ps-lg-4 pt-lg-0 pt-4">
                                                            {item.title ? (
                                                                <h6 className="text-white opacity-75 mb-2">{item.title}</h6>
                                                            ) : null}
                                                            {item.name ? (
                                                                <h1 className="text-white mb-3">{item.name}</h1>
                                                            ) : null}
                                                            {item.description ? (
                                                                <p className="text-white fw-light lead mb-lg-5 mb-4">{item.description}</p>
                                                            ) : null}
                                                            {item.buttonName && item.buttonName ? (
                                                                <a href="#" className="btn btn-white text-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">{item.buttonName}<i className="fas fa-arrow-right ms-3"></i></a>
                                                            ) : null }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="swiper-pagination swiper-pagination-white mb-lg-5 mb-4"></div>
                    </section>
                ) : null}

                <section className="sliderBrands mb-sm-8 mb-5">
                    <div className="container mb-5">
                        <h1 className="text-center mb-0">Markalarımız</h1>
                    </div>

                    <div className="container-xxl container-scrollable mb-sm-5 mb-4">
                        <ul className="nav nav-pills justify-content-sm-center">
                            {state.responseBrandList ? (
                                state.responseBrandList.map((item, i) => (
                                    <li className="nav-item" key={item.id}>
                                        <a href="#" className={`nav-link${i == 0 ? ' active' : ''}`} data-bs-toggle="pill" data-bs-target={`#tabBrands${item.id}`}>
                                            <span className="h5 mb-0">{item.name}</span>
                                        </a>
                                    </li>
                                ))
                            ) : null}
                            <li className="nav-item">
                                <NavLink to="/markalarimiz" className="nav-link">
                                    <span className="h5 mb-0">Tümü</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="container">
                        <div className="tab-content">
                            {state.responseBrandList ? (
                                state.responseBrandList.map((item, i) => (
                                    <div className={`tab-pane fade${i == 0 ? ' show active' : ''}`} id={`tabBrands${item.id}`} key={item.id}>
                                        <div className="swiper">
                                            <div className="swiper-wrapper mb-sm-5 mb-4">
                                                {item.items.map((item) => (
                                                    <div className="swiper-slide" key={item.id}>
                                                        <NavLink to={`/marka/${item.id}`} className="item d-flex flex-sm-row flex-column align-items-sm-center align-items-start pb-3 h-100 border-bottom d-block text-decoration-none">
                                                            <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain" style={{width: '60px', height: '60px'}} />
                                                            <div className="mt-sm-0 mt-3 ms-sm-3 ms-0 overflow-hidden">
                                                                <h6 className="fw-normal small mb-1 text-gray-darkest text-truncate">{item.name}</h6>
                                                                <span className="small text-blue">{item.price} ₺</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="swiper-pagination"></div>
                                        </div>
                                    </div>
                                ))
                            ) : null}
                        </div>
                    </div>
                </section>

                {state.responseAnnouncements ? (
                    <section className="mb-sm-10 mb-5">
                        <div className="container">
                            <div className="bg-orange rounded-3 px-sm-5 px-4 py-sm-8 py-4">
                                <div className="row gy-sm-5 gy-4">
                                    <div className="col-xl-4">
                                        <div className="d-flex flex-xl-column flex-row align-items-xl-start align-items-center justify-content-between mb-xl-0 mb-3">
                                            <h1 className="text-white mb-xl-4 mb-0">Duyurular</h1>
                                            <NavLink to="/icerik/duyurular" className="small text-white text-decoration-none">
                                                Tüm Duyurular<i className="fas fa-chevron-right ms-2"></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                    {state.responseAnnouncements.slice(0, 2).map((item, i) => (
                                        <div className="col-xl-4 col-md-6" key={i}>
                                            <div className="d-flex pe-xxl-5 pe-0">
                                                <i className="fas fa-info-circle h1 mb-0 text-white"></i>
                                                <div className="ms-4">
                                                    <h5 className="mb-2 text-black opacity-50 fw-normal lh-base">
                                                        {item.title}. {item.description.slice(0, 40)}... {item.url ? (
                                                            <a href={item.url} target={item.newTab ? '_blank' : '_self'} className="text-reset">{item.buttonName}</a>
                                                        ) : null}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                ) : null}

                <section className="mb-sm-10 mb-5">
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-xl-3 col-lg-4">
                                <div className="pe-lg-5 pe-0 text-lg-start text-center">
                                    <h1 className="mb-4">Bizim Dünya</h1>
                                    <p className="lead fw-light mb-0">İlginizi çekebilecek makaleler, videolar ve tartışma konuları burada.</p>
                                    <ul className="list-unstyled mt-5 mb-0 d-lg-grid d-none gap-3">
                                        <li>
                                            <NavLink to="/bizim-dergi" className="text-decoration-none">Bizim Dergi<i className="fas fa-chevron-right ms-3"></i></NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/bizim-tv" className="text-decoration-none">Bizim TV<i className="fas fa-chevron-right ms-3"></i></NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/bizim-meydan" className="text-decoration-none">Bizim Meydan<i className="fas fa-chevron-right ms-3"></i></NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="row g-sm-4 g-2" data-masonry='{"percentPosition": true }'>
                                    {[
                                        ...state.responseVideoCategories[0].items.slice(0, 3),
                                        ...state.responsePostCategories[0].items.slice(0, 6)
                                    ].sort(() => {
                                        return 0.5 - Math.random()
                                    }).map((item) => (
                                        <div className="col-xl-4 col-6" key={item.id}>
                                            {item.videoUrl ? (
                                                <a href={Constants.VIDEOS_BASE_URL + '/' + item.videoUrl} className="d-block bg-white text-decoration-none shadow rounded-3 overflow-hidden h-100" data-fancybox>
                                                    <div className="img-video bg-black">
                                                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" className="w-100 opacity-50" style={{width: '300px', height: '200px', objectFit: 'scale-down'}} />
                                                    </div>
                                                    <div className="p-sm-4 p-3">
                                                        <h5 className="fw-normal lh-base mb-3">{item.name}</h5>
                                                        <div className="text-blue small">
                                                            <span className="me-2">#TV</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            ) : (
                                                <NavLink to={`/makale/${item.id}`} className="d-block bg-white text-decoration-none shadow rounded-3 overflow-hidde">
                                                    <div className="p-sm-4 p-3">
                                                        <h5 className="fw-normal lh-base mb-3">{item.name}</h5>
                                                        <p className="text-gray-dark fw-light mb-4">{item.description.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 80)}...</p>
                                                        <div className="text-blue small">
                                                            <span className="me-2">#Dergi</span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {state.responseComments ? (
                    <section className="bg-blue-lighter py-sm-8 py-5 mb-sm-10 mb-5">
                        <div className="container">
                            <div className="row justify-content-center mb-sm-5 mb-4">
                                <div className="col-lg-6 col-md-8 text-center">
                                    <h6 className="mb-4 text-white">Bizim Meydan / Haftanın Konusu</h6>
                                    <h4 className="mb-0 lh-base text-white">{state.responseComments.subject}</h4>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-xl-8 col-lg-10">
                                    <NavLink to="/bizim-meydan" className="d-flex align-items-center text-decoration-none">
                                        {state.responseLogin.employeeFirm.profilePhoto ? (
                                            <img src={Constants.IMAGES_BASE_URL + '/' + state.responseLogin.employeeFirm.profilePhoto} alt="" className="rounded-circle shadow-sm me-3 d-sm-block d-none" style={{width: '60px', height: '60px'}} />
                                        ) : (
                                            <div className="bg-white shadow-sm rounded-circle justify-content-center align-items-center fw-bold me-3 d-sm-flex d-none" style={{width: '60px', height: '60px'}}>
                                                {state.responseLogin.employeeFirm.employee.name.slice(0, 1) + state.responseLogin.employeeFirm.employee.surname.slice(0, 1)}
                                            </div>
                                        )}
                                        <div className="commentInput bg-white shadow-lg rounded-3 py-4 px-sm-5 px-3 flex-grow-1 d-flex align-items-center justify-content-between">
                                            <div href="#" className="text-decoration-none"><h1 className="text-gray-light mb-0">Düşünceni yaz…</h1></div>
                                            <div href="#" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-4">Gönder</div>
                                        </div>
                                    </NavLink>
                                    <p className="text-center mb-0 mt-5 small text-white"><i className="fas fa-comment me-2"></i>{state.responseComments.countComment} kişi bu konu hakkında konuşuyor.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : null }

                <section className="pb-sm-10 pb-5 mb-sm-8 mb-5 border-bottom">
                    <div className="container px-0">
                        <h1 className="text-center mb-5">Yarışmalar</h1>
                        <div className="swiper sliderGames">
                            <div className="swiper-wrapper mb-sm-5 mb-4">
                                <div className="swiper-slide h-auto p-3">
                                    <div className="gameItem h-100">
                                        <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                                            <div className="image shadow-sm bg-white rounded-circle">
                                                <img src="../assets/img/game01.png" alt="" />
                                            </div>
                                            <h2 className="mb-4 fw-bold text-blue lh-sm">Hazine Avı</h2>
                                            <p className="mb-4 small">Hazine Avı sembolünu bulunan ürünü bulun ve soruya ulaşın.</p>
                                            { state.responseRemaining && state.responseRemaining.isAvailableFindQuestion ? (
                                                <NavLink to="/yarisma/hazine-avi" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">
                                                    Başla
                                                </NavLink>
                                            ) : (
                                                state.responseRemaining.remainingToFindQuestion ? (
                                                    <React.Fragment>
                                                        <div className="d-flex text-green align-items-center justify-content-between small mb-2">
                                                            <span>Kalan Süre</span>
                                                            <span>{state.responseRemaining.remainingToFindQuestion}</span>
                                                        </div>
                                                        <div className="bg-green-light rounded-pill overflow-hidden" style={{height: '8px'}}>
                                                            <div className="bg-green rounded-pill h-100" style={{width: state.responseRemaining.progressFindQuestion + '%'}}></div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide h-auto p-3">
                                    <div className="gameItem h-100">
                                        <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                                            <div className="image shadow-sm bg-white rounded-circle">
                                                <img src="../assets/img/game02.png" alt="" />
                                            </div>
                                            <h2 className="mb-4 fw-bold text-blue lh-sm">Tahmin Et</h2>
                                            <p className="mb-4 small">Özeliklerine bakarak 90 saniye içinde ürünün hangisi olduğunu bulun.</p>
                                            { state.responseRemaining && state.responseRemaining.isAvailableLookQuestion ? (
                                                <NavLink to="/yarisma/tahmin-et" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">
                                                    Başla
                                                </NavLink>
                                            ) : (
                                                state.responseRemaining.progressLookQuestion ? (
                                                    <React.Fragment>
                                                        <div className="d-flex text-green align-items-center justify-content-between small mb-2">
                                                            <span>Kalan Süre</span>
                                                            <span>{state.responseRemaining.remainingToLookQuestion}</span>
                                                        </div>
                                                        <div className="bg-green-light rounded-pill overflow-hidden" style={{height: '8px'}}>
                                                            <div className="bg-green rounded-pill h-100" style={{width: state.responseRemaining.progressLookQuestion + '%'}}></div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide h-auto p-3">
                                    <div className="gameItem h-100">
                                        <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                                            <div className="image shadow-sm bg-white rounded-circle">
                                                <img src="../assets/img/game03.png" alt="" />
                                            </div>
                                            <h2 className="mb-4 fw-bold text-blue lh-sm">Eşini Bul</h2>
                                            <p className="mb-4 small">Listelenen ürünleri ve özelliklerini doğru olarak eşleştirin.</p>
                                            { state.responseRemaining && state.responseRemaining.isAvailablePickFind ? (
                                                <NavLink to="/yarisma/esini-bul" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">
                                                    Başla
                                                </NavLink>
                                            ) : (
                                                state.responseRemaining.remainingToPickFind ? (
                                                    <React.Fragment>
                                                        <div className="d-flex text-green align-items-center justify-content-between small mb-2">
                                                            <span>Kalan Süre</span>
                                                            <span>{state.responseRemaining.remainingToPickFind}</span>
                                                        </div>
                                                        <div className="bg-green-light rounded-pill overflow-hidden" style={{height: '8px'}}>
                                                            <div className="bg-green rounded-pill h-100" style={{width: state.responseRemaining.progressPickFind + '%'}}></div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide h-auto p-3">
                                    <div className="gameItem h-100">
                                        <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                                            <div className="image shadow-sm bg-white rounded-circle">
                                                <img src="../assets/img/game04.png" alt="" />
                                            </div>
                                            <h2 className="mb-4 fw-bold text-blue lh-sm">Haftanın En’leri</h2>
                                            <p className="mb-4 small">En beğendiğiniz fotoğrafa oy verip destek olun ve puan kazanın.</p>
                                            <NavLink to="/yarisma/haftanin-enleri" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">
                                                Başla
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section>

                <section className="pb-sm-8 pb-5 mb-sm-10 mb-5 border-bottom">
                    <div className="container">
                        <div className="row gy-5 align-items-center">
                            <div className="col-lg-6 col-md-5 text-center">
                                <img src="../assets/img/poll.svg" alt="" className="img-fluid w-75" />
                            </div>
                            <div className="col-lg-6 col-md-7">
                                <form onSubmit={(event) => handleSurveySubmit(event)}>
                                    <div className="d-flex align-items-center justify-content-md-start justify-content-center mb-5">
                                        <h1 className="mb-0">Anket</h1>
                                        <span className="text-blue-light small ms-4">
                                            {state.responseSurvey[0].answeredSurvey === true ? (
                                                <React.Fragment>
                                                    Katılımınız için teşekkürler!
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <i className="fas fa-lightbulb me-2"></i>
                                                    Katıl, {state.responseSurvey[0].score} puan kazan!
                                                </React.Fragment>
                                            )}
                                        </span>
                                    </div>

                                    <h4 className="mb-sm-5 mb-4 lh-base text-md-start text-center">{state.responseSurvey[0].description}</h4>
                                    <div className="row row-cols-2 g-sm-4 g-3 mb-sm-5 mb-4">
                                        {state.responseSurvey[0].surveyChoices.map((item, i) => (
                                            <div className="col" key={item.id}>
                                                <div className={`form-check form-check-lg${state.responseSurvey[0].answeredSurvey ? ' ps-0' : ''}`}>
                                                    {!state.responseSurvey[0].answeredSurvey ? (
                                                        <input className="form-check-input" type="radio" name="surveyChoice" id={'option' + item.id} value={item.id} defaultChecked={i == 0} />
                                                    ) : null}
                                                    <label className="form-check-label h6 text-gray-dark mb-0" htmlFor={'option' + item.id}>
                                                        {item.name.replace(/<\/?[^>]+(>|$)/g, '')}
                                                        {state.responseSurvey[0].answeredSurvey ? (
                                                            <span className="small text-green ms-2">
                                                                %{Math.round((item.markedValue / state.responseSurvey[0].totalParticipant) * 100)}
                                                            </span>
                                                        ) : null}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {!state.responseSurvey[0].answeredSurvey ? (
                                        <div className="text-sm-start text-center">
                                            <button type="submit" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">Gönder</button>
                                        </div>
                                    ) : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-sm-10 mb-5">
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-xl-3 col-lg-4">
                                <div className="pe-lg-5 pe-0 text-lg-start text-center">
                                    <h1 className="mb-4">Bizim Dükkan</h1>
                                    <p className="lead fw-light mb-0">Birikmiş puanlarınızla hemen sipariş verin.</p>
                                    <ul className="list-unstyled mt-5 mb-0 d-lg-grid d-none gap-3">
                                        {state.responseProductCategories.map((item, i) => (
                                            <li key={item.id}>
                                                <NavLink to={`/bizim-dukkan/${item.subCategoryList[0].id}`} className="text-decoration-none">{item.name}</NavLink>
                                            </li>
                                        ))}
                                        <li>
                                            <NavLink to="/bizim-dukkan" className="text-decoration-none">Tümü</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="row g-sm-4 g-2">
                                    {state.responseProducts.slice(0, 6).map((item) => (
                                        <div className="col-xl-4 col-6" key={item.id}>
                                            <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                                                <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 p-3" style={{width: '300px', height: '200px'}} />
                                                <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                                                    <span className="d-block small text-blue mb-2">{item.stock} Stok</span>
                                                    <h5 className="fw-normal lh-base mb-4">{item.name}</h5>
                                                    <NavLink to={`/urun/${item.id}`} className="btn btn-progress text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto">
                                                        <span className="position-relative">{item.score} Puan<i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none"></i></span>
                                                        <span style={{width: Math.round((state.responseLogin.employeeFirm.score.score / item.score) * 100) + '%'}}></span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
            <Toast />
        </React.Fragment>
    );
};

export default Home;