import React from 'react';
import $ from 'jquery';

const Loading = () => {
    React.useEffect(() => {
        window.showLoading = (props) => {
            if (props === true) {
                $('html, body').css('overflow', 'hidden');
                $('.loading').fadeIn();
            }
            if (props === false) {
                $('html, body').css('overflow', 'visible');
                $('.loading').fadeOut();
            }
        };
    }, []);

    return (
        <div className="loading w-100 h-100 position-fixed top-0 start-0" style={{zIndex: '2000'}}>
            <div className="d-flex w-100 h-100 align-items-center justify-content-center flex-column">
                <img src="../assets/img/logo.png" alt="" className="mb-4" />
                <div className="spinner-border text-white" role="status"></div>
            </div>
        </div>
    );
};

export default Loading;