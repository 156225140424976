import React from 'react';
import {
    NavLink,
    Navigate,
    useLocation,
    useNavigate
} from 'react-router-dom';
import Countdown360, { unitFormatterBlank } from 'react-countdown360';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    TitleBackground
} from '../../components';

const GameGuessIt = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }

            //responseGameGuessIt
            fetch(Constants.API_BASE_URL + '/lookFind/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                if (response?.data?.[0]) {
                    setState({
                        ...state,
                        responseGameGuessIt: response['data'][0]
                    });
                }
            });
        }
    }, []);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    const updateUser = async (newState) => {
        let tempState = {};
    
        //responseLogin.employeeFirm
        await fetch(Constants.API_BASE_URL + '/newScore/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            tempState = {
                ...tempState,
                responseLogin: {
                    ...state.responseLogin,
                    employeeFirm: response['data']
                }
            }
        });
    
        //responseScoreTransactions
        await fetch(Constants.API_BASE_URL + '/scoreTransactions/' + tempState.responseLogin.userId + '?page=0&size=9999', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tempState.responseLogin.token
            }
        })
        .then((response) => { return response.json(); })
        .then((response) => {
            if (response?.data?.scoreTransactionDTOS) {
                tempState = {
                    ...tempState,
                    responseScoreTransactions: response['data']['scoreTransactionDTOS']
                }
            }
        });

        setState({
            ...newState ? newState : state,
            ...tempState
        });
    };

    const handleStartClick = (event) => {
        if (!state.gameGuessItStarted) {

            //responseGameGuessItQuestion
            fetch(Constants.API_BASE_URL + '/lookFindQuestion/' +  state.responseLogin.employeeFirm.firm.id + '/' + state.responseLogin.employeeFirm.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                }
            })
            .then((response) => { return response.json(); })
            .then((response) => {
                if (response?.data?.[0]) {
                    setState({
                        ...state,
                        gameGuessItStarted: Date.now(),
                        responseGameGuessItQuestion: response['data'][0]
                    });
                }
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (Object.fromEntries(new FormData(event.target)).choice.split(',')[1] == 'true') {
            fetch(Constants.API_BASE_URL + '/lookFind/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + state.responseLogin.token
                },
                body: JSON.stringify({
                    employeeFirmId: state.responseLogin.employeeFirm.id,
                    question: state.responseGameGuessItQuestion.questionId,
                    questionChoice: [parseInt(Object.fromEntries(new FormData(event.target)).choice.split(',')[0])]
                })
            })
            .then((response) => {
                if (response.status == 200) {
                    updateUser({
                        ...state,
                        gameGuessItDone: 'answerIsTrue'
                    });
                }
            });
        } else {
            setState({
                ...state,
                gameGuessItDone: 'answerIsFalse'
            });
        }
    };

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5" data-title-background="pt">
                    <TitleBackground className="d-lg-block d-none" />
                    <div className="container">
                        <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
                            <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                            <span className="me-3">»</span>
                            <span className="text-white text-decoration-none me-3">Yarışmalar</span>
                        </div>
                        <div className="row gy-5">
                            <div className="col-xl-3 col-lg-4">
                                <div className="gameItem mb-5">
                                    <div className="bg-white shadow-lg rounded-3 px-4 pb-sm-5 pb-4 text-center">
                                        <div className="image shadow-lg bg-white rounded-circle">
                                            <img src="../assets/img/game02.png" alt="" />
                                        </div>
                                        <h2 className="mb-0 fw-bold text-blue lh-sm">Tahmin Et</h2>

                                        {state.gameGuessItDone ? null : (
                                            state.responseGameGuessIt?.availableTodayQuestion ? (
                                                //available
                                                state.responseGameGuessIt?.answeredTodayQuestion ? (
                                                    //daha once cevaplamis
                                                    <p className="small text-orange mt-4"><i className="fas fa-info-circle me-2"></i>{state.responseGameGuessIt?.answeredNextDayMessage}</p>
                                                ) : (
                                                    state.gameGuessItStarted ? (
                                                        //cevaplamamis, baslamis
                                                        <div className="d-flex justify-content-center">
                                                            <Countdown360 
                                                                seconds={state.responseGameGuessItQuestion.remainTime}
                                                                onComplete={() => {
                                                                    if (!state.gameGuessItDone) {
                                                                        setState({
                                                                            ...state,
                                                                            gameGuessItDone: 'timeIsOver'
                                                                        });
                                                                    }
                                                                }}
                                                                backgroundColor="#FFFFFF"
                                                                fontColor="#4CAF50"
                                                                fontFamily="Lexend, sans-serif"
                                                                clockwise={true} 
                                                                fontSize={48}
                                                                fontWeight={600}
                                                                unitFormatter={unitFormatterBlank}
                                                                borderFillColor="#FFFFFF"
                                                                borderUnfillColor="#4CAF50"
                                                                borderWidth={8}
                                                                width={120}
                                                                smooth
                                                            />
                                                        </div>
                                                    ) : (
                                                        //cevaplamamis, baslamamis
                                                        <button type="button" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3 mt-4" onClick={(event) => handleStartClick(event)}>Başla</button>
                                                    )
                                                )
                                            ) : (
                                                //availabble degil, kalan sure
                                                state.responseRemaining.remainingToPickFind ? (
                                                    <React.Fragment>
                                                        <div className="d-flex text-green align-items-center justify-content-between small mb-2">
                                                            <span>Kalan Süre</span>
                                                            <span>{state.responseRemaining.remainingToPickFind}</span>
                                                        </div>
                                                        <div className="bg-green-light rounded-pill overflow-hidden" style={{height: '8px'}}>
                                                            <div className="bg-green rounded-pill h-100" style={{width: state.responseRemaining.progressPickFind + '%'}}></div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 offset-lg-1 offset-0">
                                <h1 className="mb-5 text-white text-white-lg" data-title-background>Tahmin Et’e hoşgeldiniz!</h1>

                                {state.gameGuessItDone ? (
                                    state.gameGuessItDone == 'timeIsOver' ? (
                                        <div className="pt-lg-5 pt-0 fw-light">
                                            <h2 className="mb-4">😔 Üzgünüz</h2>
                                            <p className="mb-0 lh-lg">Size verilen süre içerisinde yanıt veremediniz ve puan kazanamadınız. Bir sonraki yarışmada tekrar görüşmek üzere.</p>
                                        </div>
                                    ) : (
                                        state.gameGuessItDone == 'answerIsFalse' ? (
                                            <div className="pt-lg-5 pt-0 fw-light">
                                                <h2 className="mb-4">😔 Üzgünüz</h2>
                                                <p className="mb-0 lh-lg">Yanlış yanıt verdiniz ve puan kazanamadınız. Bir sonraki yarışmada tekrar görüşmek üzere.</p>
                                            </div>
                                        ) : (
                                            state.gameGuessItDone == 'answerIsTrue' ? (
                                            <div className="pt-lg-5 pt-0 fw-light">
                                                <h2 className="mb-4">🎉 Tebrikler</h2>
                                                <p className="mb-0 lh-lg">Doğru yanıt verdiniz ve <strong>10 puan</strong> kazandınız. Bir sonraki yarışmada tekrar görüşmek üzere.</p>
                                            </div>
                                            ) : null
                                        )
                                    )
                                ) : (
                                    !state.gameGuessItStarted ||
                                    !state.responseGameGuessIt?.availableTodayQuestion ||
                                    state.responseGameGuessIt?.answeredTodayQuestion ? (
                                        <div className="pt-lg-5 pt-0 fw-light">
                                            <p>Her hafta Salı, Çarşamba ve Cuma günleri karşınıza çıkar. Soruda verilen ürün özelliklerine bakarak <strong>90 saniye içinde bu ürünün hangisi olduğunu bulun ve 10 puan kazanın.</strong></p>
                                            <p>Bugünün Tahmin Et sorusunu görmek için <strong>Başla</strong> butonuna tıklayınız. Başarılar!</p>
                                        </div>
                                    ) : (
                                        <div className="pt-lg-5 pt-0">
                                            <form onSubmit={(event) => handleSubmit(event)}>
                                                <div className="row mb-6 shadow-lg rounded-3 overflow-hidden align-items-center">
                                                    <div className="col-sm-5 p-0">
                                                        <img src={Constants.IMAGES_BASE_URL + '/' + state.responseGameGuessItQuestion.icon} alt="" className="img-contain w-100 p-3" alt="" style={{maxHeight: '300px'}} />
                                                    </div>
                                                    <div className="col-sm-7 p-4">
                                                        <h4 className="mb-0 lh-base">{state.responseGameGuessItQuestion.description}</h4>
                                                    </div>
                                                </div>
                                                <div className="row row-cols-2 g-sm-4 g-3 mb-sm-5 mb-4">
                                                    {state.responseGameGuessItQuestion.questionChoices.map((item, i) => (
                                                        <div className="col" key={i}>
                                                            <div className="form-check form-check-lg">
                                                                <input className="form-check-input" type="radio" name="choice" value={[item.id, item.value]} />
                                                                <label className="form-check-label h6 text-gray-dark mb-0">
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="text-lg-start text-center">
                                                    <button type="submit" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3">Gönder</button>
                                                </div>
                                            </form>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </React.Fragment>
    );
};

export default GameGuessIt;