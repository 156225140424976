import React from 'react';
import {
    NavLink,
    Navigate,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Constants, Context } from '../../stores';
import {
    Footer,
    Header,
    TitleBackground
} from '../../components';

const Brands = () => {
    const navigate = useNavigate();
    const { brandCategory } = useParams();
    const [state, setState] = React.useContext(Context);

    React.useEffect(() => {
        if (state.isAuthorized) {

            //check expireSecond
            if (((Date.now() - state.loginTime) / 1000) + 60 >= state.responseLogin.expireSecond) {
                localStorage.clear();
                setState({
                    ...state,
                    isAuthorized: false
                });
                navigate('/login');
            }
        }
    }, []);

    if (!state.isAuthorized) {
        return <Navigate to="/login" />
    }

    if (!brandCategory) {
        return <Navigate to={`${state.responseBrandList[0].id}`} />;
    }

    return (
        <React.Fragment>
            <Header />
            <main>
                <section className="position-relative pt-5 mb-sm-10 mb-5" data-title-background="pt">
                    <TitleBackground />
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-xl-3 col-lg-4">
                                <div className="pe-lg-5 pe-0 text-lg-start text-center">
                                    <div className="d-lg-flex d-none text-white small mb-4 opacity-50" data-title-background>
                                        <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
                                        <span className="me-3">»</span>
                                        <span>Markalarımız</span>
                                    </div>
                                    <h1 className="mb-4 text-white" data-title-background>{state.responseBrandList.find((item) => { return item.id == brandCategory }).name}</h1>
                                    <p className="fw-light mb-5 text-white opacity-75" data-title-background>{state.responseBrandList.find((item) => { return item.id == brandCategory }).history.replace(/<\/?[^>]+(>|$)/g, '')}</p>

                                    <div className="row row-cols-lg-1 row-cols-2 g-sm-3 g-2 pt-5">
                                        {state.responseBrandList.map((item) => (
                                            <div className="col" key={item.id}>
                                                <NavLink to={`/markalarimiz/${item.id}`} className="brandCategoryItem d-flex align-items-center bg-white text-start text-decoration-none rounded-3 p-sm-4 p-3 h-100">
                                                    <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="flex-shrink-0 me-4 d-sm-block d-none" style={{width: '48px', height: '48px'}} />
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-1 text-blue-light">{item.name}</h5>
                                                        <small className="text-blue-light opacity-50">{item.styleCount} ürün</small>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="row g-sm-4 g-2">
                                    {state.responseBrandList.find((item) => { return item.id == brandCategory }).items.map((item) => (
                                        <div className="col-xl-4 col-6" key={item.id}>
                                            <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                                                {/* <span className="rounded-pill bg-orange text-white small position-absolute px-3 py-1 m-sm-3 m-2">Yeni</span> */}
                                                <img src={Constants.IMAGES_BASE_URL + '/' + item.icon} alt="" className="img-contain w-100 p-3" style={{width: '300px', height: '200px'}} />
                                                <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                                                    <h5 className="fw-normal lh-base mb-2">{item.name}</h5>
                                                    <span className="d-block small text-blue mb-4">{item.price} ₺</span>
                                                    <NavLink to={`/marka/${item.id}`} className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto">
                                                        İncele<i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none"></i>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Brands;